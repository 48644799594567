@use "sass:math";

.block-rewards-activation {
    --max-width: #{math.div(796px, 16px) * 1rem};
    --max-width-body: #{math.div(540px, 16px) * 1rem};
    --icon-size: #{math.div(12px, 16px) * 1rem};
}


.block-rewards-activation {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 var(--padding-s);
    @include font-sans();
    color: var(--primary-color);

    h2 {
        @include font-style("title-hotel-h3b");
        font-weight: 800;
        color: var(--primary-color);
    }

    p {
        @include font-style("body-base");
        color: var(--primary-color);
    }

    button,
    .mi-s-color-button {
        text-transform: uppercase;
        border-radius: 2em !important;
    }
}

[data-acordion] {
    --height-close: 0;
    --height-open: 0;
    --duration: .4s;
    --ease: var(--ease-in-out-quad);
    
    overflow: hidden;
    min-height: var(--height-close);
    transition: height;
    transition-timing-function: var(--ease);
    transition-duration: var(--duration);

    &[aria-expanded="true"] {
        height: calc(var(--height-close) + var(--height-open));
    }

    &[aria-expanded="false"] {
        height: var(--height-close);
        visibility: visible;
        pointer-events: all;
    }

    @media (min-width: $smartphone) {
        &[data-acordion-only-mobile] {
            height: auto;

            [data-acordion-toggle] {
                display: none;
            }
        }
    }    
}
.block-billboard-basic {
    --height: 50vh;
    --padding-v: var(--padding-xl);
    --padding-v-bottom: var(--padding-l);
    --padding-h: var(--padding-l);
    --margin-bottom: var(--padding-xxl);
    --image-position: 50% 40%;
    --opacity-overlay: 0;//.15;
    --text-transform: uppercase;
        
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-m);
        --padding-v-bottom: var(--padding-m);
        --padding-h: var(--padding-xs);
        --margin-bottom: var(--padding-m);
    }
}

.experience .block-billboard-basic {
    --margin-bottom: 0;
}

.block-billboard-basic {
    position: relative;
    min-height: var(--height);
    margin-bottom: var(--margin-bottom);
    
    &::before {
        @include z-index(1);

        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: var(--height);
        pointer-events: none;

        background-color: rgba(0, 0, 0, var(--opacity-overlay));
    }
    
    > div {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        // @include z-index(2);
        z-index: 2;
        position: relative;
        width: 100%;
        height: 100%;
        // padding: 0 var(--padding-h) var(--padding-v-bottom);
    }

    &__content:not(:last-child) {
        padding: 0 var(--padding-h);
        margin-bottom: var(--padding-v-bottom);
    }
    
    figure {
        @include z-index(0);
        // position: absolute;
        // left: 0;
        // top: 0;
        width: 100%;
        height: var(--height);
        pointer-events: none;
        background-color: var(--primary-color);

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: var(--image-position);

           
        }
    }

    .title {
        // @include text-shadow-title();

        --title-hotel-h1b-line-height: .9;
        color: var(--primary-assertive);
        text-align: center;
        position: relative;
        text-transform: var(--text-transform);
        max-width: rem(1120);
                
        b, strong {
            @include font-style('title-hotel-h1');
            --title-hotel-h1-line-height: .9;
        }

        margin-top: calc(var(--title-hotel-h1b-font-size) * -.5);
    }
    
    .button {
        bottom: 0;
        margin-top: .8em;
    }
    
    .icons {
        --bar-height: #{rem(62)};
        --radius: #{rem(31)};
        --top: 0;

        height: var(--bar-height);
        color: var(--primary-color);
        text-transform: uppercase;
        width: auto;
        position: relative;

        display: flex;
        justify-content: center;
        align-items: center;

        background-color: rgba(255,255,255,.6);
        border-radius: var(--radius);

        
        &.--sticky {
            > * {
                border-bottom: 1px solid var(--primary-medium);
            }
        }
        
        > * {
            padding: var(--padding-xs) var(--padding-s);
            width: 100%;
            justify-content: center;
            display: flex;
        }
        
        > * > * {
            align-items: center;
            justify-content: flex-start;
            display: flex;

            span {
                white-space: pre-line;
            }
            
            svg {
                height: rem(24);
                max-width: rem(24);
                min-width: rem(24);
                fill: var(--primary-assertive);
                @include text-shadow-body();
            }
        }

        a {
            color: inherit;
        }
    }

    @media (max-width: $smartphone) {
        flex-direction: column;

        > div {
            flex-grow: 1;
        }

        .icons {
            --bar-height: auto;
            --radius: var(--card-radius);
            > * {
                flex-direction: column;

                > * {
                    justify-content: flex-start;
                    width: 100%;

                    svg {
                        margin-right: var(--padding-xs);
                    }
                }
                
                > *:not(:last-child) {
                    margin-bottom: var(--padding-xxs);
                }
            }
        }
    }

    @media (min-width: $smartphone) {
        .icons {
                    
            &.--sticky {
                > * {
                    background-color: var(--primary-light);
                    position: fixed;
                    justify-content: flex-start;
                    flex-direction: column;
                    top: calc(var(--header-total) + var(--y-header));

                    > *:not(:last-child) {
                        margin-bottom: var(--padding-xxs);
                    }
                }
            }
            
            > * {
                display: flex;
                flex-wrap: wrap;
                text-transform: uppercase;
            }
            
            > * > * {
                margin-right: var(--padding-m);

                svg {
                    margin-right: var(--padding-xxs);
                }
            }
        }

        .button {
            left: 0;
        }
    }
}

@use "sass:math";

.locations-slider {
    --padding-v: var(--padding-xxl);
    --padding-h: var(--padding-s);
    --border-radius: #{math.div(256px, 16px) * 1rem};
    --border-bottom-radius: #{rem(100)};
    
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-xl);
        --padding-h: var(--padding-xs);
        --border-bottom-radius: #{rem(40)};
    }
    
    .default-slider {
        --padding-v: var(--padding-s);
        --padding-h: var(--padding-s);
        --item-height: auto;
        --item-width: calc(100% / 3);
        --gap: 0;
    }

    @media (max-width: $smartphone) {
        .default-slider {
            --padding-h: var(--padding-xs);
            --item-width: 100%;
        }
    }
}

.locations-slider {
    @include z-index(1);
    user-select: none;
    background-color: var(--primary-light);
    
    padding: var(--padding-v) 0;
    width: var(--width);
    position: relative;

    .title-default {
        margin-bottom: var(--padding-l);

        @media (max-width: $smartphone) {
            margin-bottom: 0;
        }
    }
    
    .section-title {
        max-width: rem(650);
    }

    &__header {
        padding: 0 var(--padding-h);
    }

    .default-slider__item {
        color: var(--primary-bg);

        > div {
            @include aspect-ratio(308, 123);
            text-align: center;
        }
        
        figure {
            @include z-index(0);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: var(--border-radius);
            overflow: hidden;
            
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }

        .text {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            .name,
            .loc {
                @include z-index(1);
                position: relative;
            }
    
            .name {
                text-transform: uppercase;
            }
        }
    }

    .controls {
        order: 2;

        display: flex;
        justify-content: center;
        margin-top: var(--padding-m);
        flex-basis: 100%;
        width: 100%;

        @media (max-width: $smartphone) {
            padding-bottom: 0;
        }
    }

    // @media (max-width: $smartphone) {
    //     .default-slider__item {
    //         flex: 1 0 var(--item-width);
    //     }
    // }

    @media (min-width: $smartphone) {
        .default-slider__item {
            padding: var(--padding-s) var(--padding-h);
        }
    }
}

@use "sass:math";

.kora-tab-selector {
    --height: #{rem(54px)};
    --padding: #{rem(4px)};
    --button-height: calc(var(--height) - var(--padding) * 2);
    --button-padding: calc(var(--button-height) * .4);
    --button-margin: calc(-1 * var(--button-padding) + var(--padding) * 2);
    --color: var(--primary-brand);
    --color-active: var(--primary-lighter);
    --bg-active: var(--color);

    --position-selector: 0px;
    --width-selector: var(--button-height);
        
    @media (max-width: $smartphone) {
    /*    --padding: var(--padding-m) var(--padding-s);
        --gap: 4px; */
    }
}

.kora-tab-selector {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;

    background-color: transparent;
    height: var(--height);
    padding: var(--padding);

    &::after {
        content: '';
        z-index: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: var(--height);
        border: 1px solid var(--color);
    }

    &::before {
        --x: calc(var(--position-selector) * 1px);
        --w: calc(var(--width-selector) * 1px);

        content: '';
        z-index: 1;
        position: absolute;
        top: var(--padding);
        left: 0;
        width: var(--w);
        height: var(--button-height);
        background-color: var(--color);
        border-radius: var(--height);
        transform: translateX(var(--x));

        transition: 
            transform .3s var(--ease-out-quad),
            width .3s var(--ease-out-quad);
    }
}

.kora-tab-selector > * {
    @include basic-a();

    display: inline-flex;
    align-items: center;
    position: relative;
    z-index: 2;
    height: var(--button-height);
    padding: 0 var(--button-padding);
    border-radius: var(--height);
    color: var(--color);
    background-color: transparent;
    transition: color .3s var(--ease-out-quad) .1s;

    &.--active {
        z-index: 3;
        color: var(--color-active);
        pointer-events: none;
    }

    &:not(:first-child) {   margin-left: var(--button-margin);  }
}
.book-banner-mirai {
    min-width: 90vw;
    max-width: 90vw;
    min-height: rem(62);
    margin: 0 auto;

    background-color: var(--white);
    border-radius: var(--block-radius);
    padding: rem(5);
    // overflow: hidden;
    
    @media (min-width: $tabletLandscape) {
        min-width: rem(1080);
        
        &.--banner-chain {
            max-width: rem(1280);
        }
    }

    @media (max-width: $tabletLandscape) {
        display: none;
    }
    
    form {
        border-radius: var(--block-radius);
        
        display: flex;
        align-items: center;
        justify-content: space-between;

        > * {
            flex-grow: 1;
        }

        width: 100%;
    }

    .mi-be .mi-be-promo-field label input {
        width: 8em;
    }

    @media (max-width: 1240px) {
        .mi-be .mi-be-promo-field label input {
            width: 5em;
        }
    }

    &[data-mirai-engine=mirai_be] {
        .mi-be-chain-field,
        .DateRangePickerInput,
        .mi-be-multiroom-field,
        .mi-be-promo-field {
            background-color: transparent;
            padding: 0 var(--padding-m);
            margin: 0;
        }

        .DayPicker_weekHeader_li {
            line-height: 1 !important;
        }

        .mi-be-datepicker-select .DateRangePickerInput .DateRangePickerInput_arrow svg {
            fill: var(--primary-brand);
        }
        
        .mi-be .mi-be-datepicker-select .DateRangePickerInput .DateRangePickerInput_calendarIcon,
        .mi-be .mi-be-multiroom-field span.mi-ico-dropdown,
        .mi-be .mi-be-promo-field span.mi-ico-code,
        .mi-be .mi-dropdown .mi-dropdown-button .mi-ico-arrow-down-dark {
            color: var(--primary-brand);
        }

        .mi-be-multiroom-field,
        .DateRangePickerInput {
            position: relative;

            &::after {
                content: '';
                border-right: 1px solid var(--primary-color);
                height: 50%;
                position: absolute;
                right: 0;
                top: 25%;
            }
        }

        button.mi-be-book-btn {
            @include button;
        }
    
        .mi-dropdown .mi-dropdown-button,
        .mi-be-promo-field,
        .mi-be-datepicker-select .DateRangePickerInput .DateInput {
            @include font-style('link');
            
            label,
            input,
            .DateInput_input {
                text-transform: uppercase;
                padding: 0;
                line-height: 1;
                height: auto;
                color: var(--primary-color);
                padding: var(--padding-xxs) 0;
                border-radius: var(--block-radius);

                &::placeholder,
                &:-ms-input-placeholder,
                &::-ms-input-placeholder {
                    color: var(--primary-color);
                }

                &.DateInput_input__focused {
                    background-color: var(--aux-light);
                }
            }
        }

        .mi-be-multiroom-dropdown {
            @include font-style('link');
            width: rem(300);
           
            .mi-be-multiroom-footer .mi-be-multiroom-footer-add {
                color: var(--primary-brand);
            }

            ul li .mi-be-multiroom-option-children span.react-numeric-input,
            ul li .mi-be-multiroom-option-adult span.react-numeric-input {
                input {
                    width: rem(115);
                }

                b {
                    border-color: var(--primary-brand) !important;
                        
                    i {
                        background-color: var(--primary-brand) !important;
                    }
                }
            }
        }

        .mi-be-datepicker-select .DateRangePicker_picker {
            @include font-style('link');

            .CalendarDay__default {
                color: var(--primary-brand);

                &:hover,
                &.CalendarDay__hovered_span,
                &[class*=CalendarDay__selected] {
                    background-color: var(--primary-assertive);
                }
            }
        }

        .mi-be-datepicker-clear-dates button {
            color: var(--primary-brand);
        }
    }
}

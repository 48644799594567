.mi-club-signup__title {
    @include font-style("title-hotel-h3b");
    font-weight: 800;
    color: var(--primary-color);

    p {
        @include font-style("body-base");
    }
}

.mi-club-signup .mi-form,
.mi-clubarea .mi-form {
    --form-space: #{rem(11)};
    --form-input-space: .5em;
    margin-top: 1rem;

    > small {
        display: none;
    }


   

    hr {
        display: none;
    }

    .mi-form-item__title {
        @include font-style("body-base");
        font-weight: 800;
        padding-left: 1em;
        margin-bottom: .6em;
        text-transform: uppercase;
    }

    .mi-form-item__error {
        @include font-style("card-superindex");
        padding-left: 1em;
    }

    .mi-form-item__select,
    .mi-form-item__date,
    .mi-form-item__input {
        border-color: var(--primary-brand);
        color: var(--primary-brand);
        border-radius: 2em;
    }

    .mi-form__submit {
        text-transform: uppercase;
        border-radius: 2em;
    }

    .mi-form-item:not(.mi-form-checkbox) {
        label {
            display: flex;
            align-items: center;

            > span {
                flex: 0 0 rem(150);
                font-size: rem(12);
                padding-right: .1em;

                @media (max-width: $smartphone) {
                    flex: 0 0 35%;
                    font-size: 10px;
                    padding-right: .1em;
                }
            }

            .mi-form-item__password-wrapper {
                flex: 1 0 auto;

                @media (max-width: $smartphone) {
                    flex: 0 0 65%;
                    font-size: rem(12);
                }
            }

            @media (max-width: $smartphone) {
                select,
                input {
                    font-size: rem(12);
                }
            }
        }                
    }
}
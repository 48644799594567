.card-parallax {
    --height-card: #{rem(300)};
    --icon-size: #{rem(90)};
    --width-card: #{rem(850)};
    --margin-b: var(--padding-xs);
    --padding-h: var(--padding-l);
    
    @media (max-width: $smartphone) {
        --icon-size: #{rem(45)};
        --padding-h: var(--padding-s);
        --width-card: 100%;
        --height-card: auto;
    }
}

.card-parallax {
    color: var(--primary-assertive);
    background-color: var(--primary-color);
    border-radius: rem(16);
    padding: 0;
    position: relative;
    min-height: var(--height-card);
    width: var(--width-card);
    
    .content {
        text-align: left;
        padding: var(--padding-h);
        padding-left: 0;
        align-items: center;
        justify-content: flex-start;
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;

        > * {
            flex: 1 0 100%;
            width: 100%;
        }
    }

    .card {
        background-color: transparent;
        padding: 0;
        text-align: center;
        min-width: rem(275);
        align-items: center;
        justify-content: center;
        display: flex;
        
        > div {
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        
        .icon {
            height: var(--icon-size);
            min-width: var(--icon-size);
            width: var(--icon-size);

            svg {
                fill: currentColor;
            }
        }

        .title {
            margin-top: var(--padding-xs);
            text-transform: uppercase;
        }
    }

    @media (max-width: $smartphone) {
        padding: var(--padding-h);

        .card {
            min-height: rem(150);   
        }

        .content {
            padding-right: 0;
            display: block;
        }
    }

    @media (min-width: $smartphone) {
        align-items: stretch;
        display: flex;

        .card {
            padding: 0 var(--padding-xs);
        }
    //     .wrapper {
    //         display: flex;
    //         align-items: center;
    //         justify-content: flex-start;

    //         height: max(var(--height-card), var(--height-content));
    //         width: calc(var(--width-content) + var(--width-card));
    //     }

    //     .card {
    //         flex-direction: column;
    //         height: rem(200);
    //         width: rem(200);
    //         min-width: rem(200);
    //         justify-content: center;
    //         padding: var(--padding-xs); 
    //     }

    //     .content {
    //         padding: var(--padding-l) var(--padding-m) var(--padding-l) 0;
    //         min-width: rem(400);
    //         width: rem(400);
    //     }
    }
}
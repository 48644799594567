@use "sass:math";

@mixin link-capsule() {
    --color: var(--primary-bg);
    --opacity: .4;
    --scale: 1;
    --scale-text: 1;

    @include basic-a();
    @include aspect-ratio(450,194);
    overflow: hidden;
    border-radius: 20%/50%;

    user-select: none;
    -webkit-user-drag: none;

    &:hover {
        --opacity: .24;
        --scale: 1.25;
        --scale-text: 1.05;
    }

    &.--disabled {
        --opacity: .24;
        filter: grayscale(100%);
        pointer-events: none;
    }

    > figure {
        position: absolute;
        @include z-index(1);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: scale3d(var(--scale), var(--scale), var(--scale));
        transform-origin: center center;
        transition: transform .6s var(--ease-out-quad);

        > img {
            position: absolute;
            top: 0;
            left: 0;
            width: 120%;
            height: 120%;
            transform-origin: center;
            object-fit: cover;
            object-position: center;
        }
    }
    
    &.--vertical {
        > figure {
            > img {
                width: 100%;
                width: 100%;
            }
        }
    }

    &.--horizontal {
        > figure {
            > img {
                width: 120%;
                height: 100%;
            }
        }
    }

    &::before {
        @include pseudo-element-absolute();
        @include z-index(2);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--primary-color);
        opacity: var(--opacity);
        transition: opacity .4s var(--ease-in-power1-out-power3);
        border-radius: 20%/50%;
    }

    > .text {
        position: absolute;
        @include z-index(3);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        text-transform: uppercase;
        color: var(--color);

        transform: scale3d(var(--scale-text),var(--scale-text),var(--scale-text));
        transform-origin: center center;
        transition: transform .3s var(--ease-out-quad);
    }

    .label-kora {
        display: block;
        text-align: center;
        margin-bottom: -.3em;
    }
}

.link-capsule {
    @include link-capsule();
}
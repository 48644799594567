@use "sass:math";

.block-sostenibilidad {
    --text-width: #{rem(440)};
    --height: #{rem(560)};
    --opacity-overlay: .22;
    
    @media (max-width: $smartphone) {
        --height: #{rem(560)};
        --text-width: 100%;
    }
}

.block-sostenibilidad {
    @include font-sans();

    color: var(--primary-lighter);
    text-align: center;
    position: relative;

    .wrapper {
        border-radius: var(--block-radius);
        align-items: center;
        display: flex;
        flex-direction: column;
        height: var(--height);
        justify-content: center;
        position: relative;
        overflow: hidden;

        * {
            position: relative;
        }

        p {
            margin-bottom: var(--padding-m);
            text-align: center;
            width: var(--text-width);
        }

    }

    .bg {
        @include z-index(0);
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        figure,
        img,
        video {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }

        &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, var(--opacity-overlay));
        }
    }
}

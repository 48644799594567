@use "sass:math";

.block-recomendations {
    // --height: #{rem(500)};

    .default-slider {
        --item-width: #{rem(400)};
        --item-height: #{rem(500)};
    }

    --margin-bottom: 0;
    
    @media (max-width: $smartphone) {
        --margin-bottom: 0;

        .default-slider {
            --item-width: #{rem(300)};
            --item-height: #{rem(425)};
            --padding-h: var(--padding-xs);
        }
    }
}

.block-recomendations {
    overflow: hidden;
    margin-bottom: var(--margin-bottom);

    .default-slider__item {
        a:not(.card-blog) {
            display: block;
        }
    }

    .title {
        position: relative;
        text-align: center;
        margin-bottom: 1em;
        padding: 0 var(--padding-xs);

        &.--bold,
        b,
        strong { 
            @include font-style('title-hotel-h5');
            @media (max-width: $smartphone) {
                hyphens: auto;
            }
        }
    }

    .__touch & {
        .section-default__footer {
            display: none;
        }
    }

    @media (max-width: $smartphone) {
        &__header {
            margin-bottom: var(--padding-l);
        }
    }

    @media (min-width: $smartphone) {
        padding: var(--padding-v) var(--padding-h) var(--padding-v-bottom); 
    }
}

@use "sass:math";

.block-parallax-cards {
    --padding-top: 0;
    --offset: #{rem(350)};
}

@media (min-width: $smartphone) {
    .section-default:first-child:not(.--no-margin).block-parallax-cards {
        padding-top: var(--padding-top);
        padding-bottom: var(--padding-top);
    }
}

.block-parallax-cards {
    @include font-sans();

    &__header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        > .text {
            max-width: rem(500);
            text-align: left;
            
            p:not(:last-child) {
                margin-bottom: var(--padding-s);
            }
        }
    }

    ul {
        margin-top: var(--padding-l);

        li {
            display: flex;
            justify-content: flex-start;
        }
        
        li:nth-child(even) {
            justify-content: flex-end;
        }

        li:not(:last-child) {
            margin-bottom: var(--padding-m);
        }
    }

    @media (min-width: $smartphone) {
        &__header {
            position: sticky;
            display: flex;
            top: 0;
            padding-top: calc(var(--offset));
            height: calc((var(--vh) * 100) - var(--offset));
        }

        ul {
            margin-top: var(--padding-xxxxxxl);

            li:not(:last-child) {
                margin-bottom: var(--padding-xxl);
            }
        }
    }
}

.section-title {
    --color: var(--primary-color);
    --text-transform: uppercase;
    
    .--palette-secondary & {
        --color: var(--primary-assertive);
    }

    text-align: center;
    color: var(--color);
    text-transform: var(--text-transform);
    max-width: rem(1000);
    margin: 0 auto var(--padding-l);
    position: relative;
    
    &.--secondary {
        max-width: rem(700);
    }

    &.--no-margin {
        margin-bottom: 0;
    }
    
    h1,h2,h3 {
        font-size: 1em;
    }
    
    &.--bold,
    b,
    strong { 
        @include font-style('title-hotel-h3');
        @media (max-width: $smartphone) {
            hyphens: auto;
        }
    }

    &.--secondary {
        &.--bold,
        b,
        strong { 
            @include font-style('title-hotel-h5');
        }
    }

    @media (min-width: $smartphone) {
        margin: 0 auto var(--padding-xl);
    }

    & + .section-subtitle {
        margin: 0 auto var(--padding-m);
        text-align: center;
    }
}

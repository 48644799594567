@use "sass:math";

.block-offer {
    --padding-top: calc(var(--header-height));
    --padding-bottom: var(--padding-m);
    --padding-h: var(--padding-m);
    --padding-left: 0;
    --padding-right: var(--padding-l);
    --width-bg: 50%;
    --max-width-card: #{rem(360)};
    --max-width-left: #{rem(560)};
    --padding-card-v: var(--padding-s);
    --padding-card-h: var(--padding-s);
    --min-height: 60vh;
    --opacity-overlay: .42;

    --color-left: var(--primary-lighter);
    
    @media (max-width: $smartphone) {
        --padding-top: 80vw;
        --color-left: inherit;
        --padding-left: var(--padding-xs);
        --padding-bottom: var(--padding-xs) !important;
        --padding-right: 0;
        --min-height: 10px;
        --max-width-left: 400px;
        --opacity-overlay: .1;
    }
}

.block-offer {
    position: relative;
    padding-left: var(--padding-h);
    padding-right: var(--padding-h);

    @media (max-width: $smartphone) {
        padding-top: var(--padding-top) !important;
        padding-bottom: var(--padding-bottom) !important;
    }
    
    @media (min-width: $smartphone) {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        min-height: var(--min-height);
    }

    .bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        figure {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            img {
                object-fit: cover;
                object-position: center;
                width: 100%;
                height: 100%;
            }
        }

        &::after {
            content: '';
            background-color: rgba(0, 0, 0, var(--opacity-overlay));
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
    }

    @media (max-width: $smartphone) {
        .bg {
            height: 75vw;
        }
    }
}

.block-offer__left {
    position: relative;
    flex: 0 0 50%;
    max-width: var(--max-width-left);
    min-height: var(--min-height);
    padding: var(--padding-left);
    border-radius: var(--card-radius);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: var(--color-left);

    .title {
        position: relative;
        text-transform: uppercase;
        &::first-line {
            @include font-style(serif-small);
            font-size: 1.12em;
        }
        margin-bottom: .32em;
    }

    .description {
        position: relative;
        max-width: var(--max-width-card);
    }
}

.block-offer__right {
    position: relative;
    flex: 0 0 auto;
    padding: 0 var(--padding-right);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.block-offer__includes-holder {
    position: relative;
    background-color: var(--primary-lighter);
    border-radius: var(--card-radius);
    width: 100%;
    max-width: var(--max-width-card);
    padding: var(--padding-card-v) var(--padding-card-h);

    @media (max-width: $smartphone) {
        margin-top: var(--padding-m);

        > a {
            display: none;
        } 
    }

    > a {
        margin-top: var(--padding-l);
    }

    .title {
        text-align: center;
        margin-bottom: .92em;
    }

    ul {
        list-style: none;
        counter-reset: list;
        padding: 0 var(--padding-xs);

        li {
            position: relative;
            display: flex;
            align-items: center;
            counter-increment: list;
            width: 100%;
            margin: 1em 0;

            &::before {
                content: counter(list);
                font-family: var(--font-sans);
                font-weight: 400;
                line-height: var(--line-height-sans);
                font-size: 0.6em;
                flex: 0 0 2em;
                width: 2em;
                height: 2em;
                border-radius: 50%;
                border: 1px solid currentColor;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 1.1em;
            }
        }
    }
}
.offer-note {
    @media (max-width: $smartphone) {
        --height-strong: 1.4em;
        line-height: 1.4;
    }

    @media (min-width: $smartphone) {
        --height-strong: 1.8em;
    }

    strong {
        position: relative;
        --width: 100%;
        --height: var(--height-strong);

        display: inline-block;
        padding: 0 calc(var(--height)*.4);
        margin: 0 0;

        &::before{
            content: "";
            white-space: pre;
        }
        
        &::after {
            position: absolute;
            content: '';
            top:50%;
            left:0;
            width: var(--width);
            height: var(--height);
            z-index: 0;
            transform: translateY(-50%);
            border-radius: var(--height);
            border: 1px solid var(--primary-assertive);
            color: var(--primary-color);
        }
    }

    button {
        @include basic-a();
        padding: 0;
        background-color: transparent;
        text-decoration: underline;
    }
}
@use "sass:math";

.block-gallery {    
    .default-slider {
        --item-height: auto;
        --item-width: 25vw;
        --padding-h: var(--padding-xl);
    }
    
    @media (max-width: $smartphone) {
        .default-slider {
            --item-width: 70vw;
            --padding-h: var(--padding-xs);
        }
    }
}

.block-gallery {
    position: relative;
    
    .section-title {
        color: var(--primary-bg);
    }

    .bg {
        @include z-index(0);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        img {
            height: 100%;
            object-fit: cover;
            width: 100%;
        }
    }

    .default-slider__item {
        display: flex;
        align-items: flex-start;

        &:last-of-type {
            padding-right: 0;
        }

        svg {
            height: 100%;
            width: auto;
        }
        
        figure {
            overflow: hidden;
            border-radius: var(--card-radius);
        }

        img {
            width: var(--item-width);
            height: var(--item-height);
        }
    }
}

@use "sass:math";

:root {
    --y-header: 0;

    /* UTILIZADAS */
    --gap: #{rem(30)};
    --block-radius: #{rem(30)};
    --card-radius: #{rem(20)};

    --padding-xxxxxxl: #{math.div(200px, 16px) * 1rem};
    --padding-xxxxxl: #{math.div(150px, 16px) * 1rem};
    --padding-xxxxl: #{math.div(120px, 16px) * 1rem};
    --padding-xxxl: #{math.div(100px, 16px) * 1rem};
    --padding-xxl: #{math.div(80px, 16px) * 1rem};
    --padding-xl: #{math.div(60px, 16px) * 1rem};
    --padding-l: #{math.div(50px, 16px) * 1rem};
    --padding-medium: #{math.div(40px, 16px) * 1rem};
    --padding-m: #{math.div(30px, 16px) * 1rem};
    --padding-s: #{math.div(20px, 16px) * 1rem};
    --padding-xs: #{math.div(10px, 16px) * 1rem};
    --padding-xxs: #{math.div(5px, 16px) * 1rem};
    /* */

    --padding-xxxs: #{math.div(4px, 16px) * 1rem};

    --header-height: #{math.div(85px, 16px) * 1rem};
    --button-kora-height: #{math.div(60px, 16px) * 1rem};
    --bar-height: 24px;
    --header-total: calc(var(--header-height) + var(--bar-height));
}
  
@media (max-width: $tabletLandscape) {
    :root {
        --gap: #{rem(10)};
    }
}

@media (max-width: $smartphone) {
    :root {
        --bar-height: 1px;
        --block-radius: #{rem(15)};
        --card-radius: #{rem(10)};
    }
}

.widget-alojamientos-longstays {
    --cols: 2;
    
    @media (max-width: $smartphone) {
        --cols: 1;
    }
}


.widget-alojamientos-longstays {
    .section-description {
        color: var(--primary-brand);
    }
    
    &__text {
        --body-large-font-weight: 800; 
        color: var(--primary-brand);
    }

    &__list {
        padding: 0 var(--padding-h);
    }

    @media (max-width: $smartphone) {
        
        &__list {
            padding: 0 0 var(--padding-l);

            .card-room:not(:last-child) {
                margin-bottom: var(--padding-m);
            }
        }
    }

    @media (min-width: $smartphone) {
        
        &__list {
            display: flex;
            flex-wrap: wrap;
            gap: var(--gap);
            padding-bottom: var(--padding-xl);

            .card-room {
                --width: calc(100% / var(--cols) - var(--gap) * (var(--cols) - 1) / var(--cols));
            }
        }
    }
}

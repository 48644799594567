//↗ → ← ↖

@mixin link-underline-icon() {
    --circle-size: #{rem(40)};
    --icon-size: #{rem(32)};
    --scale: var(--scale-out);
    --scale-icon: 1;
    --scale-circle: 1;
    --ease: var(--ease-in-power1-out-power3);
    --time: .8s;
    --offset: 0px;

    display: flex;
    align-items: center;

    &:not(.--invert) {
        --scale-in: 0;
        --scale-out: 1;
    } 
    &.--invert {
        --scale-in: 1;
        --scale-out: 0;
    } 

    @include basic-a();
    position: relative;
    padding-left: var(--offset);
    background: transparent;
    border: 0;

    .text {
        position: relative;
    }

    .icon-circle {
        height: var(--circle-size);
        width: var(--circle-size);
        margin-left: var(--padding-xs);
        display: flex;
        justify-content: center;
        align-items: center;
        transform: scale3d(var(--scale-circle), var(--scale-circle), 1);
        transform-origin: center;
        transition: transform var(--time) var(--ease);
    }

    svg {
        height: var(--icon-size);
        width: var(--icon-size);
        transform: scale3d(var(--scale-icon), var(--scale-icon), 1);
        transform-origin: center;
        transition: transform var(--time) var(--ease);
    }
        
    > .text::before {
        @include pseudo-element-absolute('');
        bottom: -0.22em;
        left: var(--offset);
        width: calc(100% - var(--offset));
        height: 1px;
        background-color: currentColor;
        transform: scaleX(var(--scale));
        transform-origin: left center;
        transition: transform var(--time) var(--ease);
    }

    &:hover {
     --scale: var(--scale-in);
     --scale-icon: .7;
     --scale-circle: 1.1;
     
     > .text::before {
        transform-origin: right center;
     }
    }

    &[aria-current="page"],
    &.is-active,
    &.--active,
    &.__link-active {
        --scale: var(--scale-in);
        pointer-events: none;
    }
}

.link-underline-icon {
    @include link-underline-icon();
}
.block-espacios-bussines {
    --color: var(--primary-color);
    --bullet-color: var(--primary-brand);
}

.block-espacios-bussines {
    color: var(--color);

    &__content {
        padding-top: var(--padding-l);

        > *:first-child { 
            margin: 0 auto;
            max-width: rem(524);

            p {
                margin-bottom: var(--padding-s);
            }
        }

        .link-pdf {
            margin-top: var(--padding-m);
        }
    }

    &__cta {
        display: flex;
        justify-content: center;

        .button {
            display: inline-flex;
        }
    }

    &__thumbnails {
        ul {
            li {
                align-items: center;
                display: flex;
                padding-bottom: var(--padding-s);
                padding-left: var(--padding-m);
                position: relative;
                cursor: pointer;
                text-transform: uppercase;

                &::after {
                    content: '';
                    background-color: var(--primary-bg);
                    border-radius: 50%;
                    border: 1px solid var(--bullet-color);
                    height: rem(10);
                    width: rem(10);
                    transition: background-color .2s ease-out;
                    position: absolute;
                    left: rem(-5);
                    top: rem(5);
                }

                &.--active::after {
                    background-color: var(--bullet-color);
                }

                &:not(:last-child) {
                    &::before {
                        content: '';
                        border-left: 1px solid var(--bullet-color);
                        height: 100%;
                        position: absolute;
                        left: 0;
                        top: rem(5);
                    }
                }
            }
        }
    }

    &__item {
        > * {
            margin: 0 auto;
        }
    }

    &__wrapper {
        margin-bottom: var(--padding-xxxxxl);
    }
    
    @media (max-width: #{$tabletLandscape - 1px}) {
        &__wrapper {
            margin-bottom: var(--padding-xxl);
        }

        &__list {
            padding-top: var(--padding-l);
        }

        &__thumbnails {
            display: none;
        }

        &__item:not(:last-child) {
            margin-bottom: var(--padding-m);
        }
    }

    @media (min-width: $tabletLandscape) {
        &__content {
            padding-right: var(--padding-s);

            position: sticky;
            top: 0;
        }

        &__wrapper {
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;

            > * {
                min-width: 50%;
            }
        }

        &__thumbnails {
            display: flex;
            margin-top: var(--padding-xl);
            justify-content: center;
        }

        &__item {
            padding-top: var(--padding-l);
        }
    }
}

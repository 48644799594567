@media (max-width: $smartphone) {
    .page-booking .hi-widget-container {
        display: none;
    }
}

.hi-widget-container {
    position: static !important;
}

.hi-widget-container .hi-launcher-container {
    @include z-index($z-index-chatbot);
    z-index: $z-index-chatbot;
}

.hi-widget-container .hi-webchat-container {
    @include z-index($z-index-chatbot);
    z-index: $z-index-chatbot;
}

#eighty_close_welcome_widget {
    @include z-index(999999998);
    z-index: 999999998;
}
.widget-ofertas {
    --color: var(--primary-color);
    --bullet-color: var(--primary-brand);

    @media (max-width: $smartphone) {
        .default-slider {
            --item-height: auto;
            --item-width: calc(#{rem(400)} * .75);
            --padding-h: var(--padding-xs);
        }
    }
}

.widget-ofertas {
    color: var(--color);

    &__content {
        // padding-top: var(--padding-s);
        width: 100%;
    }

    @media (max-width: $smartphone) {
        overflow: hidden;
        padding-left: 0 !important;
        padding-right: 0 !important;

        .section-title {
            padding-right: var(--padding-h);
            padding-left: var(--padding-h);
        }

        &__content {
            display: flex;
            flex-direction: column;
            width: auto;
            gap: var(--gap);
            padding-left: var(--padding-h);
            padding-right: var(--padding-h);
    
            &::-webkit-scrollbar { display: none; }

            .card-offer-large {
                display: none;
            }
        }

        .default-slider {
            overflow: initial;
        }

        // .default-slider__item {
        //     a:not(.card-offer) {
        //         display: block;
        //     }
        // }
    }

    @media (min-width: $smartphone) {
        &__content {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            gap: var(--gap);
            width: rem(730);

            .--small-version {
                display: none;
            }
        }
    }

    @media (min-width: $tabletLandscape) {
        &__content {
            width: rem(1110);
        }
    }
}

.mi-bsmodal-dialog {
    @include font-sans();
    --bg: var(--white);
    --color: var(--primary-brand);
}

.mi-bsmodal-content {
    background-color: var(--bg);
}

.mi-bsmodal-header__title {
    font-family: var(--font-sans);
    font-weight: 700;
    color: var(--color);
}

.mi-form .mi-form-item__input,
.mi-form-item__title {
    color: var(--color);
    font-family: var(--font-sans);
    font-weight: 400;
}

.mi-form-item__input:focus {
    outline-color: var(--primary-assertive);
}

.mi-login-reservation__description {
    color: var(--color);
    font-family: var(--font-sans);
}

.mi-s-color-link {
    font-family: var(--font-sans);
    font-weight: 700;
    color: var(--primary-brand) !important;

    &:hover {
        color: var(--primary-assertive) !important;
    }
}

.mi-be-modal {
    font-family: var(--font-sans);
}

[class^="mi-"] .mi-s-button, [class^="mi-"] .mi-s-color-button, .mi-cf-form-data-btn {
    font-family: var(--font-sans) !important;
    font-weight: 700 !important;
    background-color: var(--primary-brand) !important;

    &:hover {
        background-color: var(--primary-brander) !important;
    }
}

.mim-button--primary {
    background-color: var(--primary-brand) !important;
}

.mim-rs-rate-header__plus,
.mim-rs-room-header__button, .mim-rs-rate-suggestion__button, .mim-rs-rate-boards__details-button, .mim-na-option-hotel-suggestion__title a,
.mi-s-corp-color, .mi-s-wrapper-corp-color a, .mi-s-color-link, .mi-s-color-link-wrapper a, .mi-rs .mi-rs-room-occupation-btn, .mi-rs .mi-rs-room-detail-btn, .mi-rs-rate-see-more-btn, .mi-rs-suggestion-btn, .react-tabs [role=tab][aria-selected=true], .react-tabs [role=tab]:hover, .mi-rs-modal-occupation-btn:hover .mi-rs-occupation-text-legend span:first-child, .mi-rs-modal-occupation-btn.selected .mi-rs-occupation-text-legend span:first-child, .mi-rs-modal-occupation-btn.people:hover, .mi-rs-modal-occupation-btn.people.selected, .mi-rs-back-to-room-selection, .mi-na-view-all-results, .mi-rs-cart-item-edit-btn, .mi-be-datepicker-info-panel .mi-be-datepicker-clear-dates button, .mi-be .mi-be-multiroom-select .mi-be-multiroom-dropdown ul li .mi-be-multiroom-title .mi-be-multiroom-remove, .mi-be .mi-be-multiroom-select .mi-be-multiroom-dropdown .mi-be-multiroom-footer .mi-be-multiroom-footer-add, .mi-na-option-hotel > h4, .mi-rs .mi-cancelation-payment-link, .mi-rs .mi-privacy-policy-link, .mi-rs .mi-cf-form-data-link-back, .mi-rs .mi-cf-error-link-back, .mi-cf-form-data form .mi-cf-form-data-additionals div[class*="mi-cf-form-data-additionals-"] a, .mi-rs .mi-rc-btn, .mi-rc-reservation-address .mi-rc-reservation-address-mail-link, .mi-rc-reservation-address .mi-rc-reservation-address-web-link, .mi-rs .mi-rc-reservation-code .mi-rc-reservation-code-expired-link, .mi-be-modal .mi-be-modal-header button.mi-be-modal-clear-date {
    color: var(--primary-brand) !important;
}

.mi-rs-modal-occupation-btn:hover, .mi-rs-modal-occupation-btn.selected, .react-tabs [role=tab][aria-selected=true], .mi-be .mi-be-multiroom-select .mi-be-multiroom-dropdown ul li .mi-be-multiroom-option-adult span.react-numeric-input b, .mi-be .mi-be-multiroom-select .mi-be-multiroom-dropdown ul li .mi-be-multiroom-option-children span.react-numeric-input b, .mi-radio-container input[type="radio"]:checked + .mi-radio-ico:before, .mi-checkbox-container input[type="checkbox"]:checked + .mi-checkbox-ico:before, .mi-be.mi-be-mobile .mi-be-modal .mi-be-multiroom-footer .mi-be-multiroom-footer-add, .mi-be.mi-be-mobile .mi-be-modal .mi-be-multiroom-footer .mi-be-multiroom-footer-confirm {
    border-color: var(--primary-brand) !important;
}

/* TOOLTIP */
.mi-bstooltip-installation {
    @include font-sans();
}
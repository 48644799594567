.offer-content {
    --padding-v: var(--padding-xl);
    --padding-h: var(--padding-l);
    
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-l);
        --padding-h: var(--padding-m);
    }

    padding: var(--padding-v) var(--padding-h);
    text-align: center;

    .description {
        margin: 0 auto var(--padding-xxl);
        max-width: rem(680);
        text-transform: uppercase;
    }

    .label {
        margin: 0 auto var(--padding-m);
    }
    
    ul {
        text-align: left;
        max-width: rem(430);
        margin: 0 auto;
        
        li {
            align-items: center;
            display: flex;
            margin-bottom: var(--padding-s);
            padding-left: var(--padding-s);
            position: relative;

            &::after {
                content: '';
                background-color: var(--primary-color);
                border-radius: 50%;
                height: rem(5);
                width: rem(5);
                transition: background-color .2s ease-out;
                position: absolute;
                left: 0;
                top: rem(6);
            }
        }
    }
}
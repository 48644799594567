.card-distributor {
    --radius: var(--card-radius);
    --color: var(--primary-color);
    --bg: var(--aux-light);
    --width: 100%;
    --padding-h: var(--padding-s);
    --padding-v: var(--padding-s);
    --image-height: #{rem(270)};
    
    @media (min-width: $smartphone) {
        --padding-h: var(--padding-s);    
        --padding-v: var(--padding-m);
        --image-height: #{rem(380)};
        --width: #{rem(440)};
    }

    @media (min-width: $tabletLandscape) {
        --image-height: #{rem(500)};
        --width: #{rem(540)};
    }
}

.card-distributor {
    @include basic-a();

    position: relative;
    border-radius: var(--radius);
    overflow: hidden;

    color: var(--color);
    background-color: var(--bg);

    min-width: var(--width);
    width: var(--width);

    .image {
        position: relative;
        height: var(--image-height);
        width: 100%;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    .content {
        padding: var(--padding-v) var(--padding-h);
        
        .title {
            text-transform: uppercase;
            color: var(--color);
            text-align: center;
        }
        
        .button {
            margin-top: var(--padding-xs);
            width: 100%;
        }
        
    }
    
    &__footer {
        padding-top: var(--padding-s);

        /*&::before {
            content: '';
            display: block;
            border-top: 1px solid var(--primary-medium);
            margin-top: var(--padding-s);
            width: 100%;
        }*/
    }

    @media (min-width: $smartphone) {
        display: flex;
        flex-direction: column;

        .content {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }
}

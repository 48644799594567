.section-default {
    --padding-v: var(--padding-xl);
    --padding-h: var(--padding-l);
    --padding-description: 0;
    --max-width: #{rem(1440)};
    
    &.--margin-small {
        --padding-v: var(--padding-m);
    }
    
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-l);
        --padding-h: var(--padding-xs);
        --padding-description: var(--padding-xs);
    }
}

.section-default {
    &.--no-full-width  {
        margin-left: auto;
        margin-right: auto;
        max-width: calc(var(--max-width) + var(--padding-h) * 2);
    }
    
    &:first-child:not(.--no-margin) {
        padding-top: calc(var(--header-height) + var(--padding-v));
    }
    
    &:not(.--no-margin) {
        padding-top: var(--padding-v);
        padding-bottom: var(--padding-v);
    }
    
    &:not(.--no-padding) {
        padding-left: var(--padding-h);
        padding-right: var(--padding-h);
    }

    &__panel {
        padding: var(--padding-v) var(--padding-h);
    }
    
    .section-description {
        padding-left: var(--padding-description);
        padding-right: var(--padding-description);
    }

    .section-description + .section-description,
    .section-description + .section-default__wrapper {
        margin-top: var(--padding-m);
    }

    &__wrapper {
        display: flex;
        justify-content: center;
    }
    
    &__footer {
        display: flex;
        justify-content: center;
        margin-top: var(--padding-m);
        gap: var(--padding-xs);
        flex-basis: 100%;
        width: 100%;
    }

    &.--divider {
        position: relative;
    }

    .divider {
        position: absolute;
        top: 0;
        left: 50%;
        height: 1px;
        background-color: var(--primary-medium);
        width: 25vw;
        transform: translateX(-50%);
    }
    
    @media (max-width: $smartphone) {
        .divider {
            width: 80vw;
        }
    }
}

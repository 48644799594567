@use "sass:math";

.block-espacios-wow {
    .default-slider {
        --item-height: #{rem(540)};
        --item-width: #{rem(400)};
    }
    
    @media (max-width: $smartphone) {
        .default-slider {
            --item-height: auto;
            --item-width: calc(#{rem(400)} * .75);
            --padding-h: var(--padding-xs);
        }
    }
}

.block-espacios-wow {
    overflow: hidden;
    width: 100vw;
    color: var(--primary-color);

    &__header {
        position: relative;
        @include z-index(2);
    }
    
    .default-slider {
        overflow: initial;

        a {
            @include basic-a();
            display: block;
        }
    }

    .default-slider__item {
        a:not(.card-540-400) {
            display: block;
        }
    }

    @media (max-width: $smartphone) {
        &__header {
            margin-bottom: var(--padding-l);
            padding: 0 var(--padding-h);
        }

        &.section-default .section-description {
            padding-left: 0;
            padding-right: 0;
        }

        .__touch & {
            .section-default__footer {
                display: none;
            }
        }
    }
    
    @media (min-width: $smartphone) {
        display: flex;
        flex-wrap: wrap;

        &__header {
            flex: 0 1 40%;
            padding: 0 var(--padding-h);
        }

        .default-slider {
            --width: 60%;
        }
    }
    
    @media (min-width: $tabletLandscape) {
        &__header {
            flex: 0 1 34%;
        }

        .default-slider {
            --width: 66%;
        }
    }
}

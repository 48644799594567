html {
    width: 100%;
    height: 100%;
}

.language-es__item,
.language-fr__item,
.language-en__item { display: none; }

.language-es .language-es__item { display: block; }
.language-fr .language-fr__item { display: block; }
.language-en .language-en__item { display: block; }

body {
    width: 100vw;
    min-height: 100%;
    color: var(--primary-color);
    background: var(--primary-bg);

    > video {
        visibility: hidden;
        pointer-events: none;
    }

    &.--loading {
        cursor: progress;
    }
    
    main {
        width: 100%;
    }

    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
        
    &.__noScroll {
        overflow: hidden;
    }
}

.icon-circle,
.icon {
    display: inline-block;

    svg {
        height: 100%;
        width: 100%;
    }
}

.icon-circle {
    width: #{rem(32)};
    height: #{rem(32)};
    background-color: var(--primary-assertive);
    border-radius: 50%;
}

.line-parent {
    overflow: hidden;
}

*[aria-current="page"] {
    pointer-events: none;
    text-decoration: none;
}

[disabled] {
    pointer-events: none;
    opacity: .3;
}

// Tweakpane
.tp-dfwv {
    @include z-index($z-index-tweakpane);
}

.test-icons-holder {
    padding: 10vh 10vw;
    .icons { --size-icon: 32px; }
    .bullets { --size-icon: 18px; }
    .assets { --size-icon: 32px; }
    .decos { --size-icon: 60px; }

    > div {
        --mod-icon: 3;
        --size-view: calc(var(--size-icon) * var(--mod-icon));
        display: block;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin: 20px 0;

        .info {
            width: 100%;
        }

        h2 {
            display: block;
            width: 100%;
            font-size: 32px;
            font-family: Helvetica, sans-serif;
            color: black;
        }

        .label {
            display: block;
            width: 100%;
            display: block;
            font-family: monospace;
            font-size: 12px;
            color: #333;
        }

        .holder {
            position: relative;
            width: calc(var(--size-view) + 40px);

            .name {
                font-family: Helvetica, sans-serif;
                font-size: 12px;
                color: black;
                text-align: center;
                margin: 2em 0 .2em;
                text-align: center;
            }

            .name-class {
                font-family: monospace;
                font-size: 8px;
                color: #333;
                text-align: center;
                margin-bottom: 5px;
                text-align: center;
            }

            .cont {
                position: absolute;
                top: 0;
                right: 0;
                font-family: monospace;
                font-size: 8px;
                color: #333;
                text-align: center;
                width: 21px;
                height: 21px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        figure {
            width: calc(var(--size-view) + 40px);
            height: calc(var(--size-view) + 40px);
            border: 1px solid #d8d8d8;
            border-radius: 2px;
            padding: 20px;

            svg {
                width: var(--size-view);
                height: var(--size-view);
                fill: black;
            }
        }
    }
}

.link-pdf {
    display: flex;
    justify-content: center;
}
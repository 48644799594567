@use "sass:math";

.block-faqs {
    --max-width: #{math.div(796px, 16px) * 1rem};
    --max-width-body: #{math.div(540px, 16px) * 1rem};
    --icon-size: #{math.div(12px, 16px) * 1rem};
    --bg-circle: transparent;
    --bg-cross: currentColor;
    
    @media (max-width: $smartphone) {
        --icon-size: #{math.div(8px, 16px) * 1rem};
    }
}

.block-faqs {
    color: var(--primary-brand);

    @include font-sans();

    &__ul {
        list-style: none;
        max-width: var(--max-width);
        margin: 0 auto;
    
        //ACORDEON
        article {
            --duration: 0.4s;
            --ease: var(--ease-in-out-quad);
            
            background-color: var(--primary-lighter);
            border-radius: var(--card-radius);
            overflow: hidden;
            margin-bottom: var(--padding-xs);
        }
    
        article > button {
            --rot: 0deg;
            --strokeCross: #{math.div(1px, 16px) * 1rem};
            
            @include basic-a();
            background-color: transparent;
            color: var(--primary-color);
            position: relative;
            width: 100%;
            padding: var(--padding-s) var(--padding-l) var(--padding-s) var(--padding-m);
            text-align: left;
            min-height: var(--icon-size);
    
            > span {
                position: absolute;
                right: var(--padding-s);
                top: 50%;

                padding: var(--padding-xs);

                border-radius: 50%;
                border: 1px solid currentColor;
    
                transform-origin: 50% 50%;
                transform: translate3d(0, -50%, 0) rotate(var(--rot));
    
                transition: transform 0.4s var(--ease);
                overflow: hidden;
    
                &::after,
                &::before {
                    content: '';
                    position: absolute;
                    @include z-index(2);
                    transform: translate3d(-50%, -50%, 2px) scale3d(1, 1, 1);
                }
                
                &::after {
                    top: 50%;
                    left: 50%;
                    width: var(--strokeCross);
                    height: var(--icon-size);
                    background: var(--bg-cross);
                    transition: background 0.2s var(--ease), transform .5s var(--ease-in-back-out-circ);
                }
                
                &::before {
                    top: 50%;
                    left: 50%;
                    width: var(--icon-size);
                    height: var(--strokeCross);
                    background: var(--bg-cross);
                    transition: background 0.2s var(--ease), transform .5s var(--ease-in-back-out-circ);
                }
                
                span {
                    @include z-index(1);
                    background-color: var(--bg-circle);
                    height: 100%;
                    top: 50%;
                    position: absolute;
                    left: 50%;
                    width: 100%;
                    transform: translate3d(-50%, -50%, 1px) scale3d(0, 0, 1);
                    transition: transform .3s var(--ease);
                    border-radius: 50%;
                }
            }

            @include isCursor () {
                &:hover {
                    span {
                        &::after,
                        &::before {
                            transform: translate3d(-50%, -50%, 2px) scale3d(1.5, 1.5, 1);
                        }

                        span {
                            transform: translate3d(-50%, -50%, 1px) scale3d(1.1, 1.1, 1);
                        }
                    }
                }
            }
        }

        article > div {
            color: var(--primary-color);
            padding: 0 var(--padding-m) var(--padding-s);
            
            * {
                max-width: var(--max-width-body);
            }

            p {
                margin-bottom: 1em;
                &:last-of-type { margin-bottom: 0; }
            }
        }
    
        article[aria-expanded='false'] {
            visibility: visible;
            pointer-events: initial;
        }
        
        article[aria-expanded='true'] > button {
            --rot: 135deg;

            span {
                &::after,
                &::before {
                    transform: translate3d(-50%, -50%, 2px) scale3d(1.5, 1.5, 1);
                }

                span {
                    transform: translate3d(-50%, -50%, 1px) scale3d(1, 1, 1);
                }
            }
        }
    }

    @media (min-width: $smartphone) {
        article {
            margin-bottom: var(--padding-m);
        }

        article > button {
            padding: var(--padding-l) var(--padding-xxxl) var(--padding-l) var(--padding-l);

            > span {
                right: var(--padding-m);
                padding: var(--padding-s);
            }
        }

        article > div {
            padding: 0 var(--padding-l) var(--padding-l);
        }

    }
}

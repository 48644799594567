.card-blog {
    --width: #{rem(400)};
    --height: #{rem(500)};

    --radius: var(--card-radius);
    --radius-inside: #{rem(15)};
    // --width: var(--default-width);
    // --height: var(--default-height);

    --mask: .2;
    --padding-horizontal: var(--padding-s);

    --color: var(--primary-color);

    &.--type-1 { --bg: var(--aux-light); }
    &.--type-2 { --bg: var(--aux-medium); }
    &.--type-3 { --bg: var(--aux-dark); }
    &.--light { --bg: var(--primary-lighter); }
    
    --bg-category: var(--primary-lighter);
    --bg-icon: var(--primary-assertive);

    @media (max-width: $smartphone) {
        --width: 100%;

        &:not(.--slider-item) {
            --height: auto;
        }

        &.--slider-item {
            --height: 100%;
        }
    }
}

.card-blog {
    @include basic-a();

    position: relative;
    width: var(--width);
    height: var(--height);
    border-radius: var(--radius);

    color: var(--color);
    background-color: var(--bg);
}

.card-blog.--type-1 {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    > figure {
        order: 1;
        position: relative;
        height: #{rem(300)};
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    .card-blog__content {
        order: 2;
        padding: var(--padding-m) var(--padding-horizontal) var(--padding-m);
        height: #{rem(200)};
    }

    @media (max-width: $smartphone) {
        > figure {
            height: #{rem(250)};
        }
    }
}

.card-blog.--type-2 {
    display: flex;
    flex-direction: column;  
    justify-content: space-between; 
    overflow: hidden;
    
    .card-blog__content {
        order: 1;
        padding: calc(var(--padding-m) + #{rem(40)}) var(--padding-horizontal) var(--padding-xs);
    }

    > figure {
        order: 2;
        position: relative;
        height: #{rem(300)};
        margin: 0 var(--padding-s);
        border-top-left-radius: calc(var(--width) - var(--padding-s) * 2);
        border-top-right-radius: calc(var(--width) - var(--padding-s) * 2);
        overflow: hidden;
        width: calc(100% - 2 * var(--padding-s));

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    @media (max-width: $smartphone) {
        > figure {
            height: #{rem(250)};
            border-top-left-radius: calc(100vw - var(--padding-horizontal) * 2 - var(--padding-xs) * 2);
            border-top-right-radius: calc(100vw - var(--padding-horizontal) * 2 - var(--padding-xs) * 2);
        }
    }
}

.card-blog.--type-3 {
    display: flex;
    flex-direction: column;  
    justify-content: space-between; 
    overflow: hidden;
    
    .card-blog__content {
        order: 1;
        padding: calc(var(--padding-m) + #{rem(40)}) var(--padding-horizontal) var(--padding-s);
    }

    &.--light .card-blog__content {
        .title {
            text-transform: initial;
        }
    }

    > figure {
        order: 2;
        position: relative;
        height: #{rem(300)};
        margin: 0 var(--padding-xs) var(--padding-xs);
        border-radius: var(--radius-inside);
        overflow: hidden;
        width: calc(100% - 2 * var(--padding-xs));

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    @media (max-width: $smartphone) {
        > figure {
            height: #{rem(220)};
        }
    }
}

.card-blog__content {
    padding: var(--padding-m) var(--padding-horizontal) var(--padding-m);
   
    .title {
        color: var(--color); 
        text-transform: uppercase;
        //margin-bottom: .9em;
    }

    .distance {
        color: var(--color);
    }

    .subtitle {            
        color: var(--color);
        margin-top: 1.42em;

        @media (min-width: $smartphone) {
            @include ellipsis-multiline(5);
        }

        @media (max-width: $smartphone) {
            //@include ellipsis-multiline(3);
        }
    }
}

.card-blog__header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: #{rem(15)} #{rem(15)};

    > .category {
        padding: #{rem(8)} #{rem(15)};
        border-radius: var(--radius);
        background-color: var(--bg-category);
    }

    > .icon {
        width: #{rem(40)};
        height: #{rem(40)};
        border-radius: 50%;
        background-color: var(--bg-icon);
        padding: #{rem(10)};
        
        svg {
            width: #{rem(20)};
            height: #{rem(20)};
        }
    }
}
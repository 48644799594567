.modal-gallery {
    --img-height: 100vh;
    --img-width: 100vw;
}

.modal-gallery {
    @include z-index($z-index-gallery);

    background-color: var(--primary-color);
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    
    .slider {
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;

        position: relative;
        display: flex;
        flex-direction: column;
        user-select: none;
        cursor: grab; 

        &:active {
            cursor: grabbing;
        }

        > .holder {
            order: 1;
            position: relative;
            height: var(--img-height);
            display: flex;
            flex-wrap: nowrap;
            overflow: hidden;
        }

        &__item {
            position: relative;
            flex: 0 0 var(--img-width);
            width: var(--img-width);
            overflow: hidden;
            display: flex;
            align-items: flex-start;

            figure {
                height: 100%;
                width: 100%;
            }

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;

                -webkit-user-drag: none;
                -khtml-user-drag: none;
                -moz-user-drag: none;
                -o-user-drag: none;
                user-drag: none;
            }
        }

        &__controls {
            @include z-index(1);

            display: flex;
            justify-content: center;
            gap: var(--padding-xs);
            bottom: var(--padding-m);

            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .close {
        position: absolute;
        top: var(--padding-xs);
        right: var(--padding-xs);
        fill: var(--primary-assertive);
        background: transparent;
        padding: 0;
        
        width: rem(80);
        height: rem(80);

        svg {
            height: 100%;
            width: 100%;
        }
    }
}

@use "sass:math";

.block-list-left-right {
    --min-height: #{rem(150)};
    --width: #{rem(525)};
    
    @media (max-width: $smartphone) {
        --width: 100%;
    }
}

.block-list-left-right {
    &__item {
        margin-bottom: var(--padding-xl);
        
        figure {
            overflow: hidden;
            border-radius: var(--card-radius);
        }
    }

    &__body {
        max-width: rem(525);
    }

    p {
        margin-bottom: 1em;
        &:last-of-type { margin-bottom: 0; }
    }

    @media (max-width: $smartphone) {
        &__item {
            h2 {
                margin: var(--padding-s) 0;
            }

            &:nth-child(even) {
                h2 {
                    text-align: right;
                }
            }

            &:last-of-type { margin-bottom: 0; }
        }
    }

    @media (min-width: $smartphone) {
        &__item {
            margin-bottom: var(--padding-xxxxl);
            display: flex;
            gap: var(--gap);
            align-items: flex-end;
            padding: 0 var(--padding-xxxl);

            figure {
                padding-top: 0;
                height: rem(500);
                width: rem(400);

                img {
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }

            h2 {
                margin-bottom: var(--padding-m);
            }

            &:nth-child(even) {
                flex-direction: row-reverse;
            }

            &:last-of-type { margin-bottom: 0; }
        }
    }
}

@use "sass:math";

#Footer {
    --bg: var(--primary-color);
    --bg-fake: var(--primary-light);
    --color-newsletter-title: var(--primary-brand);
    --color-rrss: var(--primary-brand);
    --color-dark: var(--primary-brand);
    --color-wrapper: var(--primary-bg);
    --color-legals: var(--primary-brand);
    --color-links-small: var(--primary-bg);
    --color-logo: var(--primary-brand);
    --color-text: var(--primary-bg);
    --color-mega-link: var(--color-dark);
    
    --max-width: #{rem(1440)};
    --padding-v: var(--padding-xl);
    --padding-h: var(--padding-xl);
    --logo-width: #{math.div(165px, 16px) * 1rem};
    --weare-font-size: 10vw;
    --icon-radius: #{rem(8px)};

    --border-bottom-radius: #{rem(100)};
        
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-m);
        --padding-h: var(--padding-s);
        --weare-font-size: 16vw; 
        --border-bottom-radius: #{rem(40)};
    }
}

#Footer {
    @include z-index(1);
    overflow: hidden;
    background-color: var(--bg);

    .page-booking & {
        display: none;
    }

    .first {
        width: 100%;
        background-color: var(--bg-fake);

        border-bottom-left-radius: var(--border-bottom-radius);
        border-bottom-right-radius: var(--border-bottom-radius);

        a {
            @include basic-a();
            display: block;
            @include font-sans-display();
            font-size: var(--weare-font-size);
            text-align: center;
            color: var(--color-mega-link);
            line-height: .8;
            padding: .4em 0;
            opacity: .6;
            transition: 
                opacity var(--ease-out-circ) 1.4s,
                color var(--ease-out-quad) .6s;

            strong {
                @include font-serif();
                font-size: 1.1em;
                line-height: inherit;
            }

            &:hover {
                opacity: 1;
                //color: var(--primary-assertive);

                transition: 
                    opacity var(--ease-out-circ) 1.4s,
                    color var(--ease-out-quad) .6s;
            }
        }
    }

    .wrapper {
        max-width: var(--max-width);
        margin: 0 auto;
        color: var(--color-text);
        display: flex;
        flex-wrap: wrap;
        padding: var(--padding-v) var(--padding-h) 0;
        position: relative;
    }
    
    

    .top a,
    .bottom a {
        @include basic-a();
    }
    
    .top {
        .newsletter {
            h2 {
                color: var(--color-newsletter-title);
                margin-bottom: var(--padding-m);
            }
            
            p {
                margin-bottom: var(--padding-m);
            }
        }
    
        .form {
            margin-bottom: var(--padding-m);
        }
    
        .rrss {
            a {
                display: block;
                color: var(--color-rrss);
                margin-bottom: var(--padding-xxxs);
            }
        }

        .badges {
            list-style: none;
            margin: var(--padding-m) 0 var(--padding-s);
            display: flex;
            gap: var(--padding-xs);
            justify-content: flex-start;

            > li {
                width: 20%;
            }

            figure {
                border-radius: var(--icon-radius);
            }
        }
    }

    .icon--logo-footer {
        svg {
            fill: var(--green);
        }
    }

    .center {
        position: relative;

        .button {
            display: inline-block;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        .image {
            @include aspect-ratio(390, 441);
            position: relative;

            video {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
            }
        }

        .mask {
            position: absolute;
            bottom: 0;
            top: 0;
            left: 0;
            right: 0;

            svg {
                height: 100%;
                width: 100%;

                path {
                    fill: var(--bg);
                }
            }
        }

        figure {
            bottom: 0;
            top: 0;
            position: absolute;
            left: 0;
            right: 0;
            overflow: hidden;
        }

        img {
            height: 100%;
            object-fit: cover;
            width: 100%;
        }
    }
    
    .bottom {
        > a {
            display: block;
            height: rem(35);
            width: rem(166);
            margin-bottom: var(--padding-m);
            
            svg {
                display: block;
                height: rem(35);
                width: rem(166);
                fill: var(--color-logo);
            }
        }
    
        .address {
            margin-bottom: var(--padding-m);

            a {
                display: block;
                margin-bottom: .1em;
                color: var(--color-wrapper);
            }

            .addr {
                margin-bottom: var(--padding-m);
            }
        }

        .legals {
            > div a,
            a {
                color: var(--color-legals);
                display: block;
                margin-bottom: var(--padding-xs);
                text-decoration: underline;
            }

            > div a,
            a.menu-item {
                color: var(--color-links-small);
            }
        }
    }

    @media (max-width: $smartphone) {
        .wrapper {
            flex-wrap: wrap;

            > * {
                flex: 1 0 100%;
                width: 100%;
            }
        }

        .top {
            .rrss {
                display: flex;
                justify-content: space-around;
                a {
                    padding: .25em 0;
                }
            }
        }

        .top,
        .bottom {
            margin-bottom: var(--padding-l);

            .address {
                margin-bottom: var(--padding-m);
                    
                a {
                    display: block;
                    padding: .4em 0;
                    margin-bottom: 0;
                }

                .addr {
                    margin-bottom: .4em;
                }
            }

            .legals {
                margin-top: var(--padding-xl);
                text-align: center;
                > div a,
                a {
                    padding: .5em 0;
                }
            }
        }

        .top >  .badges {
            justify-content: space-around;

            > li {
                width: 25%;
            }
        }

        .bottom {
            order: 2;
        }

        .center {
            order: 3;
        }
    }
    
    @media (min-width: $smartphone) {
        .wrapper {
            gap: 5%;
            align-items: flex-end;
        }

        .top,
        .center,
        .bottom {
            flex: 0 0 30%;
        }

        .bottom {
            padding-bottom: var(--padding-l);
        }

        .top {
            padding-bottom: 0;
        }
    }
}
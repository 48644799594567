@use "sass:math";

#Header {
    --width: 100%;
    --height: var(--header-total);
    --height-logo: #{math.div(48px, 16px) * 1rem};
    --color: var(--white);
    --bg: linear-gradient(180deg, rgba(0,0,0, .4) 0%, rgba(0,0,0,0) 100%);;
    // --bg-bar: transparent;
    // --color-bar: var(--primary-bg);
    // --border-bar: var(--primary-bg);
    --color-bar: var(--color);
    --border-bar: var(--color);

    --padding-v: calc((var(--header-height) - var(--button-kora-height))/2);
    --padding-h: var(--padding-s);
    --font-size: var(--font-size-base);

    --opacity-bg: 0;
    --y-button: max(var(--y-header), calc(-1 * var(--bar-height))); 

    // &.--with-bg {
    --opacity-bg: 1;
        // --color-bar: var(--primary-lighter);
    // }

    @include z-index($z-index-header);

    @media (max-width: $smartphone) {
        --color: var(--primary-assertive);

        @include z-index($z-index-book-button);
        --height-logo: #{rem(50)};
        --font-size: var(--font-size-xxsmall);
    }
}

@media (min-width: $smartphone) {
    .header-no-transparent #Header,
    .page-booking #Header,
    #Header.--with-bg {
        --color: var(--primary-brand);
        --color-bar: var(--color-darkest);
        --border-bar: var(--primary-brand);
        --bg: var(--primary-light);
    }

    .page-booking #Header nav, .page-booking #Header .toggle-menu {
        display: none;
    }

    .page-booking #Header,
    #Header.--with-bg {
        .header__content::before {
            border-bottom: 1px solid var(--primary-medium);
        }
    }
}

#Header {
    position: fixed;
    width: var(--width);
    height: var(--height);
    right: 0;
    top: 0;

    nav {
        display: flex;

        a {
            position: relative;
        }
    }

    &.--with-bg {
        .multilink .holder::before {
            background-color: var(--primary-medium);
        }
    }

    .--palette-secondary &.--with-bg .multilink:hover .holder > a {
        color: var(--primary-color);
    }

    .multilink {
        --opacity-link: 0;

        &.--no-link-mode {
            --opacity-link: 1;
        }
        
        position: relative;
        display: flex;
        align-items: flex-start;
        flex: 0 0 auto;
        margin-right: 2em;

        &:hover {
            --opacity-link: 1;
        }

        > a {
            opacity: 0;
            pointer-events: none;
            margin: 0;
        }

        .holder {
            position: absolute;
            top:0;
            left:0;
            display: inline-flex;
            flex-direction: column;
            //@include z-index(2);
            
            justify-content: flex-start;
            align-items: flex-start;
            
            min-width: 100%;
            height: auto;

            &::before {
                content: '';
                position: absolute;
                top:-1em;
                left:-1em;
                width: calc(100% + 2em);
                height:  calc(100% + 2em);
                border-radius: .53em;
                background-color: var(--primary-color);
                opacity: var(--opacity-link);
                transition: opacity .4s var(--ease-out-quad);
            }

            .--palette-secondary &::before {
                background-color: var(--primary-brand);
            }

            > a {
                position: relative;
                white-space: nowrap;
                margin-right: 0;

                &.--no-link-mode {
                    pointer-events: none;
                }
            }

            > a:not(:first-child) {
                margin-top: 1em;
                opacity: var(--opacity-link);
                transition: opacity .6s var(--ease-out-quad);
            }
        }
    }
}

.header__content {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 12;
    transform: translate3d(0, var(--y-header), 12px);
    pointer-events: none;

    &::before {
        position: absolute;
        content: '';
        pointer-events: none;
        top:0;
        left:0;
        width: 100%;
        height: var(--height);
        opacity: var(--opacity-bg);
        transition: opacity .4s ease-out;

        @media (min-width: $smartphone) {
            background: rgb(4,31,30);
            background: var(--bg);
        }
    }
}

.header__content > .top_bar {
    position: relative;
    width: 100%;
    height: var(--bar-height);
    color: var(--color-bar);
    padding: 0 var(--padding-h);
    pointer-events: all;
    display: flex;
    justify-content: space-between;
    align-items: center;

    // background-color: var(--bg-bar);

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: var(--padding-h);
        width: calc(100% - var(--padding-h) * 2);
        height: auto;
        border-bottom: 1px solid var(--border-bar);
        opacity: .4;
    }

    > .holder {
        flex: 0 0 auto;
        width: auto;
        height: var(--bar-height);
        display: flex;
       
        a:not(:last-of-type) {
            margin: 0 var(--padding-xs) 0 0;
        }
    }

    > .langs {
        --size-icon: 1.2em;
        --top-icon: calc((var(--bar-height) - var(--size-icon)) / 2);

        flex: 0 0 auto;
        width: auto;
        position: relative;
        height: var(--bar-height);
        padding: 0 var(--padding-h) 0 calc(var(--size-icon) + .45em);

        > .icon {
            position: absolute;
            top: var(--top-icon);
            left: 0;
            width: var(--size-icon);
            height: var(--size-icon);
            fill: currentColor;
        }

        a {
            margin: 0 var(--padding-xxs);
            text-transform: uppercase;

            &.is-active,
            &[aria-current="page"] {
                pointer-events: none;
            }
        }
    }

    .header__mirai-login {
        .mi-club-login__access-wrapper {
            font-family: var(--header-bar-font-family);
            font-weight: var(--header-bar-font-weight);
            font-size: var(--header-bar-font-size);
            line-height: var(--header-bar-line-height);
            letter-spacing: var(--header-bar-letter-spacing);

            button {
                height: var(--bar-height);
                padding: 0;
                margin: 0 0 0 var(--padding-xs);

                .mi-club-login__access-icon {
                    margin-right: .3em;
                }
            }
        }
    }
}

.header__content > .content {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: var(--header-height);
    padding: 0 var(--padding-h);
    color: var(--color);
    text-transform: uppercase;
    pointer-events: none;

    a, button {
        pointer-events: all;
    }

    .logo {
        @include basic-a();
        height: var(--height-logo);
        width: auto;
        pointer-events: all;

        svg {
            height: 100%;
            width: auto;
            fill: var(--color);
        }
    }

    .toggle-menu {
        color: inherit;
        background-color: transparent;
        padding: 0;
        position: relative;
        text-transform: uppercase;
        margin-right: 2em;
        pointer-events: all;
    }

    a {
        @include basic-a();
        color: inherit;
        margin-right: 2em;
        
        &[aria-current="page"] {
            pointer-events: none;
        }
    }

    @media (max-width: $tabletLandscape) {
        a:nth-child(2),
        a:nth-child(3),
        a:nth-child(4) {
           display: none;
        }
    }
}

.header__toggle-button {
    display: none;
}

.header__book-button.button-kora {
    --color1: var(--primary-brand);
    --color2: var(--primary-lighter);

    position: fixed;
    pointer-events: all;
    @include z-index($z-index-toggle);
    right: var(--padding-h);
    top: calc(var(--padding-v) + var(--bar-height));
    transform: translate3d(0, var(--y-button), 11px);
}

@media (max-width: $smartphone) {
    .header__toggle-button.button-kora {
        position: absolute;
        display: block;
        pointer-events: all;
        right: var(--padding-h);
        top: calc(var(--padding-v) + var(--bar-height));
    }

    .toggle-menu {
        display: none;
    }


    #Header {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto;
        height: 100%;//calc(var(--vh) * 100);
        pointer-events: none;

        > .title {
            grid-column: 1 / 2;
            grid-row: 1 / 2;
        }

        > .date {
            grid-column: 1 / 2;
            grid-row: 2 / 3;

            align-self: flex-end;
        }
    }

    #Header nav {
        display: none;
    }

    .header__content {
        > .content {
               a {
                margin-right: 1.5em;
            }
        }

        > .top_bar {
            display: none;
        }
    }

    .header__book-button.button-kora {
        top: auto;
        left: 50%;
        transform: translateX(-50%);
        bottom: var(--padding-v);
        pointer-events: all;
    }
}

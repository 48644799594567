.default-slider {
    --width: 100%;
    --item-height: auto;
    --item-width: 100%;
    --padding-h: 0;
    --padding-v: 0;
    --padding-h-holder: var(--padding-h);
    --min-width-holder: 0;

    @media (max-width: $smartphone) {
        --padding-h-holder: var(--padding-h);

        &.--mobile-center-gsap {
            --padding-h-holder: calc((100vw - var(--item-width))/2) !important;
            --min-width-holder: max-content; //En GSAP necesitamos que el holder sea del tamaño que marcan sus items
        }
    }
}

.default-slider {
    @include z-index(1);
    position: relative;
    display: flex;
    flex-direction: column;
    user-select: none;
    width: var(--width);
    padding: var(--padding-v) var(--padding-h-holder);
    overflow: hidden;
    
    cursor: grab; 
    &:active {
        cursor: grabbing;
    }

    &__holder {
        order: 1;
        position: relative;
        min-width: var(--min-width-holder);
        height: var(--item-height);
        display: flex;
        flex-wrap: nowrap;
    }

    &__item {
        position: relative;
        overflow: hidden;
        height: 100%;
        flex: 1 0 var(--item-width);
        width: var(--item-width);

        &:last-of-type {
            padding-right: var(--padding-h);
        }

        -webkit-user-select: none; /* Safari 3.1+ */ 
        -moz-user-select: none; /* Firefox 2+ */ 
        -ms-user-select: none; /* IE 10+ */ 
        user-select: none; /* Standard syntax */ 
        user-drag: none;  
        -webkit-user-drag: none; 

        * {
            -webkit-user-select: none; /* Safari 3.1+ */ 
            -moz-user-select: none; /* Firefox 2+ */ 
            -ms-user-select: none; /* IE 10+ */ 
            user-select: none; /* Standard syntax */ 
            user-drag: none;  
            -webkit-user-drag: none; 
        }
    }

    @media (max-width: $smartphone) {
        &__holder {
            gap: 0;
        }

        &__item:last-of-type {
            padding-right: 0;
        }

        &__item:not(:last-of-type) {
            margin-right: var(--padding-h);
        }
    }

    @media (min-width: $smartphone) {
        &__holder {
            gap: var(--gap);
        }
    }
}

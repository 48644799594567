.experience {
    --top: var(--padding-xl);
    --align: center;
    
    @media (max-width: $smartphone) {
        --top: var(--padding-l);
        --align: left;
    }

    // .section-title {
    //     color: var(--primary-brand);
    // }
    
    .section-default:not(.block-wysiwyg):not(.block-wysiwyg-with-image) + .block-wysiwyg {
        margin-top: calc(-1 * var(--top));
    }

    .block-wysiwyg {
        --max-width-content: #{rem(525)};
        text-align: var(--align);
    }
    
    .--2-columns {
        --max-width-content: #{rem(650)};
        text-align: left;
    }

    .--3-columns {
        --max-width-content: #{rem(950)};
        text-align: left;
    }
}

.tab-selector {
    --height: #{rem(50px)};
    --padding: var(--padding-xxs);
    --button-height: calc(var(--height) - var(--padding) * 2);
    --button-padding: var(--padding-xs);

    --color: var(--primary-color);
    --bg: var(--primary-bg);
}

.tab-selector {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;

    background-color: transparent;
    padding: var(--padding);
    border: 1px solid var(--bg);
    border-radius: var(--height);

    .btn {
        @include basic-a();

        display: flex;
        align-items: center;
        justify-content: center;

        position: relative;
        z-index: 2;
        height: var(--button-height);
        padding: 0 var(--button-padding);
        border-radius: var(--height);
        color: var(--bg);
        min-width: rem(115);

        transition: color .3s var(--ease-out-quad) .1s;

        &[aria-current="page"] {
            z-index: 3;
            color: var(--color);
            background-color: var(--bg);
            pointer-events: none;
        }
    }
}

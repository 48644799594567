
@media screen and (min-width: 1280px) {
    #CybotCookiebotDialog[data-template=bottom] {
        transform: translate(-50%, -110%) !important;
    }

    #CybotCookiebotDialog.CybotEdge {
        max-width: 1400px !important;
        min-width: 300px !important;
      	width:95%;
    }
}

#CybotCookiebotDialogHeader{
    display: none !important;
}

//GENERAL
#CookiebotWidget a,
#CybotCookiebotDialog a, 
#CookiebotWidget *,
#CybotCookiebotDialog * { 
    font-family: var(--font-sans) !important;
    color: var(--primary-color) !important;
    line-height: 1.27 !important;
}
#CybotCookiebotDialog,
#CookiebotWidget svg {
    fill: var(--primary-color) !important;
}

//LINKS/BUTTON ARROWS
#CybotCookiebotDialog a:before,
#CybotCookiebotDialog a:after,
#CybotCookiebotDialog button:before,
#CybotCookiebotDialog button:after {
    border-color: var(--primary-color) !important;
}

//BOTONES
#CookiebotWidget-btn-withdraw,
.CybotCookiebotDialogBodyButton {
    border-color: var(--primary-color) !important;
    color: var(--primary-color) !important;
}
//BOTON FONDO COLOR
#CookiebotWidget-btn-change,
#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
    background-color: var(--primary-color) !important;
    color: white !important;
    border-color: var(--primary-color) !important;
}


//CHECKS
.CybotCookiebotDialogBodyLevelButtonSlider {
    background-color: var(--primary-color) !important;
}
#CybotCookiebotDialog form input[type=checkbox][disabled]:checked+.CybotCookiebotDialogBodyLevelButtonSlider {
    background-color: #EEEEEE !important;
    pointer-events: none !important;
}
#CybotCookiebotDialog form input[type=checkbox]:checked+.CybotCookiebotDialogBodyLevelButtonSlider {
    background-color: var(--primary-brand) !important;
    pointer-events: none !important;
}

//TABS
.CybotCookiebotDialogNavItemLink {
    color: var(--primary-color) !important;
}
.CybotCookiebotDialogNavItemLink.CybotCookiebotDialogActive {
    color: var(--primary-brand) !important;
    border-color: var(--primary-brand) !important;
}

//BTN OPEN WIDGET
#CookiebotWidget .CookiebotWidget-logo svg circle {
    fill: var(--primary-color) !important;
}


//LOGO COOKIEBOT
.CookiebotWidget-main-logo,
#CybotCookiebotDialogPoweredbyLink,
#CybotCookiebotDialogHeaderLogosWrapper {
    display: none !important;
}




#CookiebotWidget *[aria-expanded="false"],
#CybotCookiebotDialog *[aria-expanded="false"] {
    visibility: visible !important;
    pointer-events: all !important;
}

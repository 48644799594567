.block-image {
    --width: #{rem(950)};
    
    @media (max-width: $smartphone) {
        --width: 100%;
    }
}

.block-image {
    > div {
        width: 100%;
    }

    &:not(.--fullscreen) {
        > div {
            max-width: var(--width);
            margin: 0 auto;
        }
    }
}

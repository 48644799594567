.card-400-700 {
    --radius: var(--card-radius);
    --radius-inside: #{rem(15)};
    --width: #{rem(700)};
    --height: #{rem(400)};
    --padding: var(--padding-s);

    --width-figure: calc(var(--width) / 2 - var(--gap) / 2); 
    --height-figure: var(--width-figure); 
    
    --width-content: calc(var(--width) / 2 - var(--gap) / 2); 
    
    --color: var(--primary-color);
    
    &.--type-1 { --bg: var(--aux-light); }
    &.--type-2 { --bg: var(--aux-medium); }
    &.--type-3 { --bg: var(--aux-dark); }
    
    @media (max-width: $smartphone) {
        --width: 100%;
        --height: auto;
        --width-content: 100%; 
        --width-figure: 100%;
    }
}

.card-400-700 {
    a:not(.button) {
        @include basic-a();
        color: inherit;
        text-decoration: underline;
        font-weight: bold;
    }

    position: relative;
    width: var(--width);
    height: var(--height);
    border-radius: var(--radius);
    padding: var(--padding);
    display: block;
    max-width: 100%;

    color: var(--color);
    background-color: var(--bg);

    .button {
        pointer-events: initial;

        &:not(.--show) {
            opacity: 0;
            pointer-events: none;

            @media (max-width: $smartphone) {
                display: none;
            }
        }
    }

    > .content {
        text-align: left;
        width: var(--width-content);
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        > *:first-child { 
            margin-bottom: var(--padding-s);
        }

        > *:last-child { 
            margin-top: var(--padding-s);
        }
       
        .title {
            text-transform: uppercase;
            color: var(--color-title); 
            margin-bottom: .8em;
        }

        .subtitle {            
            color: var(--color-subtitle);

            @media (max-width: $smartphone) {
                //@include ellipsis-multiline(3);
            }
        }
    }

    .image {
        display: flex;
        align-items: center;
        width:  var(--width-figure);

        figure {
            position: relative;
            width:  var(--width-figure);
            height: var(--height-figure);
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
    }
        
    @media (max-width: $smartphone) {
        .image {
            padding-top: 100%;
            position: relative;
            margin-bottom: var(--padding-s);

            figure {
                position: absolute;
                left: 0;
                top: 0;
            }
        }
    }

    @media (min-width: $smartphone) {
        display: flex;
        gap: var(--gap);
        justify-content: space-between;

        .image {
            figure {
                height: var(--height-figure);
            }
        }
    }
}

.card-400-700.--type-1 {
    .image figure {
        border-radius: 50%;
    }
}

.card-400-700.--type-2 {
    .image figure {
        border-top-right-radius: var(--width-figure);
        border-top-left-radius: var(--width-figure);
        border-bottom-right-radius: #{rem(30)};
        border-bottom-left-radius: #{rem(30)};
    }
}

.card-400-700.--type-3 {
    .image figure {
        border-bottom-right-radius: var(--width-figure);
        border-bottom-left-radius: var(--width-figure);
        border-top-right-radius: #{rem(30)};
        border-top-left-radius: #{rem(30)};
    }
}


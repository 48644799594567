@use "sass:math";

.block-button,
.block-wysiwyg {
    --color: var(--primary-color);
    --max-width-content: #{math.div(600px, 16px) * 1rem};
    --max-width-title: #{math.div(936px, 16px) * 1rem};
    --max-width-h1: #{math.div(1200px, 16px) * 1rem};
    --text-align: center;
}

.block-wysiwyg-with-image > .block-wysiwyg {
    --text-align: left;
    --align: left;
}

.section-default:not(.--no-padding).block-wysiwyg-with-image,
.section-default.block-wysiwyg-with-image,
.section-default:not(.--no-padding).block-wysiwyg,
.section-default.block-wysiwyg {
    padding-top: 0;
    padding-bottom: 0;
}

.block-wysiwyg + .block-wysiwyg-with-image,
.block-wysiwyg-with-image + .block-wysiwyg-with-image,
.block-wysiwyg-with-image + .block-wysiwyg,
.block-wysiwyg + .block-wysiwyg {
    margin: 0;
}

.block-wysiwyg-with-image + .block-wysiwyg {
    margin: 0;
}

.block-wysiwyg-with-image.--no-margin,
.block-wysiwyg.--no-margin {
    h2:first-child,
    h3:first-child,
    h4:first-child,
    h5:first-child,
    p:first-child,
    ol:first-child,
    ul:first-child {
        margin-top: 0;
    }
}

.block-button {
    .button {
        text-decoration: none;
        width: auto;
        max-width: var(--max-width-content);
        margin: 0 auto;
    }
}

.block-wysiwyg-with-image {
    display: flex;
    gap: var(--padding-s);

    > .media {
        padding: 0 8%;

        // max-height: 80vh;

        // img {
        //     border-radius: var(--card-radius);
        //     height: 100%;
        //     width: 100%;
        //     object-fit: contain;
        //     object-position: top center;
        // }
                       
        // @media (min-width: $tabletPortrait) {
            // max-height: 90vh;

        //     figure {
        //         height: 100%;
        //     }
        // }

        figure {
            border-radius: var(--card-radius);
            overflow: hidden;
        //     object-fit: contain;
        //     max-height: 100%;
        }
    }

    > .media,
    > .block-wysiwyg {
        flex: 1 0 50%;
    }

    @media (max-width: $tabletPortrait) {
        display: block;

        > .media,
        > .block-wysiwyg {
            flex: 0 0 100%;
        }
    }

    &.--invert {
        > .media { order: 1; }
        > .block-wysiwyg { order: 2; }
    }

    ul, ol {
        column-count: 2;
    }
}

.block-wysiwyg {
    color: var(--color);

    @include font-style('body-base');

    @media (max-width: $smartphone) {
        padding-left: calc(var(--padding-xs) * 2) !important;
        padding-right: calc(var(--padding-xs) * 2) !important;
    }

    p,ol,ul {
        max-width: var(--max-width-content);
        margin: 1em auto;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-top: 0;
            margin-bottom: 2em;
        }
    }

    h3,h4,h5 {
        max-width: var(--max-width-content);
        margin: 1.2em auto .6em;
    }

    h2 {
        max-width: var(--max-width-title);
        margin: 1.2em auto .8em;
    }

    h1 {
       
        @include font-style('title-hotel-h3');
        text-align: var(--text-align);
        font-size: 5rem;
        margin: 0 auto 1em;
    }

    h2 {
        @include font-style('title-hotel-h3');
        text-align: var(--text-align);
    }

    h3 {
        @include font-style('blog-h3');
    }

    h4 {
        @include font-style('blog-h4');
        text-align: var(--text-align);
    }

    svg {
        max-width: 100px;
        height: auto;
    }

    ul {
        list-style: none;
        li {
            &::after {
                content: '';
                background-color: currentColor;
                border-radius: 50%;
                height: rem(4);
                width: rem(4);
                position: absolute;
                left: rem(0);
                top: rem(8);
            }
           
            position: relative;
            margin: 0 0 1em 1em;
            padding-left: 1em;
        }
    }

    ol {
        list-style: decimal;
       
        li {
            margin: 0 0 1em 2em;
        }
    }

    a:not(.button) {
        @include basic-a();
        text-decoration: underline;
        color: inherit;
    }

    .button {
        text-decoration: none;
        width: auto;
        max-width: var(--max-width-content);
        margin: 0 auto;
    }

    @media (min-width: $tabletPortrait) {
        &.--3-columns {
            ol,ul {
                margin-top: var(--padding-l);
                column-count: 3;
                column-gap: var(--padding-s);
                padding-left: 5%;
            }
        }

        &.--2-columns {
            ol,ul {
                column-count: 2;
                column-gap: var(--padding-s);
            }
        }
    }

    @media (max-width: $smartphone) {
        h2 {
            font-size: 20px;
        }
    }
}

.block-billboard-split {
    --height: 100vh;
    --padding-h: var(--padding-l);
    --padding-v: var(--padding-xxxl);
    --opacity-overlay: 0;//.1;
    --opacity-overlay-split: .1;
    --image-position: 50% 40%;
    --text-transform: uppercase;

    &.--dark {
        --opacity-overlay: .38;
    }
    
    @media (max-width: $smartphone) {
        --padding-h: var(--padding-s);
        --padding-v: var(--padding-xxl);
    }

    @media (max-width: $tabletPortrait) {
        --height: 80vh;
    }

    @media (min-aspect-ratio: 4/2) {
        --image-position: 50% 20%;
    }
}

.block-billboard-split {
    &__fold {
        display: flex;
        align-items: center;
        justify-content: center;

        position: relative;
        min-height: var(--height);
        padding: calc(var(--header-total) + var(--padding-v)) var(--padding-h) var(--padding-v);

        > div {
            margin-bottom: var(--padding-xl);
        }

        figure {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
            background-color: var(--primary-color);

            video,
            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                object-position: var(--image-position);
            }

            &::after {
                content: '';
                background-color: rgba(0, 0, 0, var(--opacity-overlay));
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
            }
        }

        h1 {
            --title-hotel-h1-line-height: .9;
            color: var(--primary-lighter);
            text-align: center;
            position: relative;
            text-transform: var(--text-transform);
            margin: 0 auto var(--padding-xxxl);

            &:last-child {
                margin-bottom: 0;
            }

            strong {
                color: var(--primary-assertive);
                font-weight: 800;
            }
        }

        h1:has(+ .subtitle) {
            margin: 0;
        }

        .subtitle {
            position: relative;
            color: var(--primary-lighter);
            text-align: center;
            margin: var(--padding-xs) auto var(--padding-xxxl);
        }
    }

    &__text {
        max-width: rem(705);
        position: relative;
        color: var(--primary-lighter);
        margin: 0 auto;

        p:not(:last-child) {
            margin-bottom: var(--padding-s);
        }
    }

    &__split {
        margin-top: calc(-1 * var(--padding-v));
        padding: 0 var(--padding-h) var(--padding-xl);
        
        .split {
            position: relative;
            overflow: hidden;
            border-radius: var(--block-radius);

            figure {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
            }

            figure {
                pointer-events: none;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }

            .content {
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                background-color: rgba(0, 0, 0, var(--opacity-overlay-split));

                h2,
                h3 {
                    color: var(--primary-lighter);
                    margin-bottom: var(--padding-m);
                    text-align: center;
                    @include text-shadow-title();
                }

                b,
                strong { 
                    @include font-style('title-hotel-h3');
                }
            }
        }
    }
    
    @media (max-width: $smartphone) {
        &__fold {
            h1 {
                margin: 0 auto var(--padding-xxl);
            }
        }

        &__split {
            > div:not(:last-child) {
                margin-bottom: var(--gap);
            }

            .split {
                padding: var(--padding-m) var(--padding-s);
                
            }
        }
    }
    
    @media (min-width: $smartphone) {
        &__text {
            gap: var(--gap);
            column-count: 2;
        }

        &__split {
            display: flex;
            gap: var(--gap);

            > div {
                width: calc(50% - var(--gap) / 2);
            }

            .split {
                padding-top: 70%;

                .content {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;

                    h2,h3 {
                        margin-bottom: var(--padding-xl);
                    }
                }
            }
        }
    }
}

.block-billboard-home {
    --padding-h: var(--padding-l);
    --padding-h-advantages: var(--padding-m);
    --promise-width: #{rem(410)};
    --promise-height: #{rem(500)};
    --cols: 4;
    --card-width: calc((var(--advantages-width) - var(--padding-h-advantages) * 2 - var(--gap) * (var(--cols) - 2)) / var(--cols));
    --opacity-overlay: 0;//.1;
    --image-position: 50% 40%;
    --padding-bottom-advantages: var(--padding-);
    --image-height: 100vh;
    --title-transform: uppercase;
    --title-text-transform: uppercase;

    @media (min-width: $tabletLandscape) {
        --advantages-width:  #{rem(1080)};
    }

    @media (max-width: $tabletLandscape) {
        --advantages-width: 90vw;
        --promise-width: 30vw;
        --promise-height: 34vw;
    }

    @media (max-width: $smartphone) {
        --advantages-width: 100vw;
        --card-width: calc((var(--advantages-width)) / var(--cols));
        --padding-h-advantages: var(--padding-xs);
        --promise-width: #{rem(300)};
        --promise-height: #{rem(365)};
        --padding-h: var(--padding-s);
        --cols: 2;
        --image-height: 100%;
    }

    @media (min-aspect-ratio: 4/2) {
        --image-position: 50% 28%;
    }
}

.block-billboard-home {
    &__fold-mobile, 
    &__fold-desktop {
        figure {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: var(--image-height);
            pointer-events: none;
            background-color: var(--primary-color);

            img,
            video {
                height: 100%;
                width: 100%;
                object-fit: cover;
                object-position: var(--image-position);
            }

            &::after {
                content: '';
                background-color: rgba(0, 0, 0, var(--opacity-overlay));
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
            }
        }
    }

    &__title {
        color: var(--primary-lighter);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h1, p {
            position: relative;
            text-align: center;
        }

        p {
            @include text-shadow-title();
            --title-hotel-h1-line-height: .9;
            max-width: rem(775);
            text-transform: var(--title-transform);
            margin: 0;
        }
        
        h1 {
            @include text-shadow-body();
            max-width: rem(936);
            margin-bottom: var(--padding-xs);
            text-transform: var(--title-text-transform);
        }
    }

    &__book {
        position: relative;
        display: flex;
        justify-content: center;
        text-align: center;
    }

    &__advantages {
        position: relative;
        display: flex;
        justify-content: center;
        text-align: center;
               
        > div {
            padding: var(--padding-s) 0 0;
            position: relative;
            
            h2 {
                margin-bottom: var(--padding-s);
            }
    
            .advantages {
                display: grid;
                gap: var(--gap);
                grid-template-columns: repeat(var(--cols), 1fr);
                width: var(--advantages-width);
            }
        }
    }
    
    &__promises {
        margin-top: var(--padding-l);
        overflow: hidden;
        
        * {
            -webkit-user-drag: none;
            -khtml-user-drag: none;
            -moz-user-drag: none;
            -o-user-drag: none;
            user-drag: none;
        }
    }

    &__text {
        margin-bottom: var(--padding-m);

        p {
            color: currentColor;
            margin: 0 auto;
            max-width: rem(525);
            text-align: center;

            &:not(:last-child) {
                margin-bottom: var(--padding-s);
            }
        }
    }
    
    &__slider {
        margin-top: var(--padding-l);
        --animation: 20s;
        
        > div {
            display: flex;
            padding-left: var(--gap);
            gap: var(--gap);
        }

        &.--simple {
            > div {
                justify-content: center;
                align-items: center;
            }
        }

        > .aux {
            margin-left: 0;
            left: 100%;
        }

        .promise {
            @include basic-a();
            height: var(--promise-height);
            position: relative;
            min-width: var(--promise-width);
    
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            
            figure {
                @include z-index(0);
                
                border-top-left-radius: var(--promise-width);
                border-top-right-radius: var(--promise-width);
                border-bottom-left-radius: var(--card-radius);
                border-bottom-right-radius: var(--card-radius);
                overflow: hidden;
                // position: absolute;
                // left: 0;
                // top: 0;
                width: 100%;
                height: 100%;
                
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            
            .text {
                @include z-index(1);

                color: var(--primary-assertive);
                // position: relative;
                text-align: center;
                width: var(--promise-width);
                white-space: initial;
                margin-bottom: var(--padding-s);
                text-transform: uppercase;

                position: absolute;
                left: 0;
                // top: 0;
                bottom: var(--padding-s);
            }

            // .subtext {
            //     color: var(--primary-lighter);
            //     margin-top: var(--padding-xl);
            //     margin-bottom: var(--padding-xxs);
            //     position: relative;
            //     text-transform: uppercase;
            // }
        }
    }

    @media (max-width: $smartphone) {
        &__book {
            display: none;
        }

        &__fold-mobile {
            position: relative;
            height: 70vh;
            padding: 0 var(--padding-s);
        }

        &__title {
            height: 100%;
            padding: calc(var(--header-height) * .5) 0 0;

            p {
                padding: 0 var(--padding-s);
            }
        }
        
        &__advantages {
            @include z-index(1);
            width: 100%;
            margin-top: 0;
            position: relative;
            overflow: hidden;

            > div {
                width: 100%;

                &:before {
                    @include z-index(0);

                    content: '';
                    background-color: var(--primary-lighter);
                    border-radius: 0;
                    position: absolute;
                    left: 0;
                    right:  0;
                    top: 0;
                    height: 100%;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }

            .advantages {
                grid-template-columns: repeat(var(--cols), 1fr);
            }

            .advantages-wrapper {
                width: 100%;
                padding-left: 0;
                padding: 0 0 var(--padding-h-advantages);
            }

            ul {
                padding: 0 var(--padding-h-advantages);
            }
            
            h2 {
                position: relative;
            }

            .advantages {
                position: relative;
            }
        }

        &__text {
            padding: 0 var(--padding-h);
        }
    }

    @media (min-width: $smartphone) {
        &__fold-desktop {
            overflow: hidden;
            position: relative;
        }

        &__advantages {
            margin-top: var(--padding-s);
            padding-bottom: var(--padding-bottom-advantages);

            > div {
                background-color: var(--primary-lighter);
                border-radius: var(--block-radius);
                padding: var(--padding-h-advantages) 0;
            }

            ul {
                padding: 0 var(--padding-h-advantages);
            }
        }

        &__title {
            min-height: 60vh;
        }
    }

    @media (max-height: $smartphone) {
        &__title {
            min-height: 80vh;
        }
    }

    @media (min-width: $tabletLandscape) {
        &__title {
            min-height: 85vh;
        }
    }
}

@media (min-width: $smartphone) {
    .block-billboard-home__slider.basic-marquee {
        --animation: 40s;
    }
}

@media (min-width: $smartphone) and (max-width: $tabletLandscape) {
    .block-billboard-home__slider {
        .promise > .text {
            font-size: 4vw;
        }
    }
}

@media (max-width: $smartphone) {
    .block-billboard-home__promises {
        overflow: visible;
    }

    .block-billboard-home__slider.--simple,
    .block-billboard-home__slider.basic-marquee {
        width: 100%;
        padding: 0;
       
        &:not(.--invert),
        &.--invert {
            animation: none;
        }

        .aux {
            display: none;
        }

        > div {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
        }

        .promise {
            width: var(--promise-width);
            &:not(:last-child) {
                margin-bottom: calc(var(--promise-width) * -.35);
            }

            .text {
                top: 50%;
                bottom: auto;
            }
        }
    }
}
.mi-bsmodal-content {
    --bg: var(--white);
    --color: var(--black);
    --radius: .4em;
    --form-input-space: 16px;
    --input-height: calc(var(--form-input-space) * 2 + 1em);
    --corporative-color: var(--fluor);

    @media (max-width: $smartphone) {
        
    }


    font-size: 16px;
    line-height: 1.25;

    * {
        line-height: 1.25;
    }
}


.mi-bsmodal-content {
    background-color: var(--bg);
    color: var(--color);
    border-radius: var(--radius);

    .mi-form-item__input, .mi-form__submit {
        border-radius: var(--input-height) !important;
    }
}

.mi-club-login__logged {
    --font-size: 9px;
    font-size: var(--font-size) !important;
}

.mi-club-login__logged-links {
    gap: 1em;
}

.mi-club-login__access-icon {
    color: var(--fluor) !important;
}
.features {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    
    .feature{
        align-items: center;
        display: flex;
        justify-content: flex-start;
        flex: 0 1 33%;

        .icon {
            display: block;
            height: rem(18);
            margin-right: rem(5);
            width: rem(18);

            svg {
                fill: var(--primary-color);
            }
        }
    }
}

.card-experience-category {
    --radius: var(--card-radius);
    --padding: var(--padding-m) var(--padding-s);    
    --color: var(--primary-lighter);
    --color-name: var(--primary-assertive);
    --bg: var(--primary-color);
    --height: #{rem(450)};
    --width: #{rem(750)};
    
    @media (max-width: $smartphone) {
        --width: 100%;
        --height: 60vw;
        --padding: var(--padding-s);
    }
}

.card-experience-category {
    @include basic-a();

    display: flex;
    position: relative;
    border-radius: var(--radius);
    overflow: hidden;

    color: var(--color);
    background-color: var(--bg);

    width: var(--width);
    min-height: var(--height);
    min-width: var(--width);
    
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
        user-drag: none;
    }

    .title {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        @include text-shadow-title();

        @media (max-width: $smartphone) {
            font-size: 20px !important;
        }
    }

    @media (max-width: $smartphone) {
        display: flex;
        flex-direction: column;
        width: var(--width);

        .image {
            flex: 0 0 rem(200);
            height: rem(200);
        }

        .content {
            flex: 1 0 auto;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .icons {
            --card-body-font-size: #{rem(11)};
        }
    }
}

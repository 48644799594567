.card-offer {
    --default-width: #{rem(350)};
    --default-height: #{rem(400)};
    --radius: var(--card-radius);
    --width: var(--default-width);
    --height: var(--default-height);
    --circle-size: #{rem(90)};

    --padding: var(--padding-s);    
    --color: var(--primary-bg);
    --color-offer: var(--primary-assertive);
    --bg: var(--primary-brand);
    --mask: .45;

    @media (max-width: $smartphone) {
        --width: calc(var(--default-width) * .85);
        --height: calc(var(--default-height) * .85);
        --circle-size: #{rem(72)};
    }
}

.card-offer {
    @include basic-a();

    position: relative;
    width: var(--width);
    min-width: var(--width);
    height: var(--height);
    border-radius: var(--radius);
    overflow: hidden;

    background-color: var(--bg);
    
    > figure img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

    > .content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        background: rgba(0, 0, 0, var(--mask));       

        color: var(--color);
        text-align: center;
        text-transform: uppercase;

        > .title::first-line {
            @include font-style(serif-small);
        }
    }

    > .content-aux {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: var(--padding);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .offer {
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--color-offer);
            width: var(--circle-size);
            height: var(--circle-size);
            border-radius: 50%;
            border: 1px solid currentColor;
            text-align: center;

            &:empty {
                opacity: 0;
            }
        }
    }
}

.widget-alojamientos-ecofriendly {
    .section-description {
        color: var(--primary-brand);
    }
    
    &__text {
        --body-large-font-weight: 800;
        text-align: center;
        max-width: rem(920);
        color: var(--primary-brand);
    }
    
    &__cards {
        margin-top: var(--padding-l);
    }

    @media (max-width: $smartphone) {
        &__text {
            margin-top: var(--padding-m);
            text-align: center;
        }
        
        &__cards {
            .card-distributor:first-child {
                margin-bottom: var(--padding-s);
            }
        }
    }

    @media (min-width: $smartphone) {
        &__cards {
            margin-top: var(--padding-xl);
            display: flex;
            gap: var(--gap);
            justify-content: center;
        }
    }
}

@use "sass:math";

.block-gallery-light {
    --bg: var(--primary-lighter);

    .default-slider {
        --item-height: #{rem(400)};
        --item-width: auto;
        --padding-h: var(--padding-l);
    }
    
    @media (max-width: $smartphone) {
        .default-slider {
            --item-height: 100%;
            --item-width: calc(100vw - var(--gap) * 2);
            --padding-h: var(--padding-xs);
        }
    }
}

.block-gallery-light {
    position: relative;
    background-color: var(--bg);

    .default-slider__item {
        border-radius: var(--card-radius);
        
        span {
            --radius: var(--card-radius);
            --bg-category: var(--primary-lighter);

            color: var(--primary-color);
            position: absolute;
            display: block;
            top: #{rem(15)};
            left: #{rem(15)};
            padding: #{rem(8)} #{rem(15)};
            border-radius: var(--radius);
            background-color: var(--bg-category);
        }

        &:last-of-type {
            padding-right: 0;
        }

        img, svg, figure {
            width: var(--item-width);
            height: var(--item-height);
        }
    }

    @media (max-width: $smartphone) {
        .section-title,
        .section-description {
            padding: 0 var(--padding-h);
        }
    }
}

.block-gazette {
    --cols: 1;
    
    @media (min-width: $smartphone) {
        --cols: 2;
    }

    @media (min-width: $tabletLandscape) {
        --cols: 3;
    }
}

.block-gazette {
    @include font-sans();

    &__content {
        display: grid;
        flex-wrap: wrap;
        grid-template-columns: repeat(var(--cols), 1fr);
        gap: var(--gap);
    }

    &__categories {
        .button {
            &[aria-current="page"] {
                color: var(--bg);
                background-color: var(--color);
                pointer-events: none;
            }
        }
    }

    &__pagination ol {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: var(--padding-xl) 0;
        padding: 0 var(--padding-m);

        li {
            margin-right: var(--padding-s);
        }
    }

    @media (max-width: $smartphone) {
        &__categories {
            display: flex;
            margin: var(--padding-s) 0 var(--padding-m);
            padding-bottom: var(--padding-xxs);
            @include scroll-horizontal();

            .button {
                margin-right: var(--padding-xxs);
            }
        }

        .block-gazette__categories {
            margin-left: calc(-1 * var(--padding-h));
            margin-right: calc(-1 * var(--padding-h));
            padding-left: var(--padding-h);
            
            li:last-of-type {
                padding-right: var(--padding-h);
            }
        }

    }

    @media (min-width: $smartphone) {
        &__categories {
            display: flex;
            justify-content: center;
            gap: var(--padding-s);
            margin: var(--padding-l) 0 var(--padding-xxxl);
        }
    }
}

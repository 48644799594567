.card-500-400 {
    --radius: var(--card-radius);
    --radius-inside: #{rem(15)};
    --width: #{rem(400)};
    --height: #{rem(500)};
    --mask: .2;
    --padding-horizontal: var(--padding-s);

    --color: var(--primary-color);
    --bg: var(--primary-lightest);
    --bg-category: var(--primary-lightest);
}

.card-500-400 {
    @include basic-a();

    position: relative;
    width: var(--width);
    height: var(--height);
    border-radius: var(--radius);

    display: flex;
    flex-direction: column;   
    overflow: hidden;

    color: var(--color);
    background-color: var(--bg);

    > figure {
        order: 1;
        position: relative;
        height: #{rem(300)};
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    > .content {
        order: 2;
        padding: var(--padding-m) var(--padding-horizontal) var(--padding-m);
        height: #{rem(200)};

        .title {
            text-transform: uppercase;
            color: var(--color-title); 
            margin-bottom: .9em;
        }
        .subtitle {            
            color: var(--color-subtitle);
        }
    }

    header {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: #{rem(15)} #{rem(15)};
    
        > .category {
            padding: #{rem(8)} #{rem(15)};
            border-radius: var(--radius);
            background-color: var(--bg-category);
        }
    }
}
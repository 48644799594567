.section-description {
    > div {
        color: currentColor;
        margin: 0 auto;
        max-width: rem(525);
    }

    a {
        @include basic-a();
        text-decoration: underline;
        color: inherit;
    }

    p:not(:last-child) {
        margin-bottom: var(--padding-m);
    }

    ul {
        margin-top: var(--padding-m);
        text-align: left;
        width: 100%;
        
        li {
            position: relative;
            padding-left: rem(20);
            
            &:not(:last-child) {
                margin-bottom: var(--padding-s);
            }
            
            &:before {
                content: '·';
                font-size: 3em;
                position: absolute;
                left: 0;
                top: 0;
                line-height: .3;
            }
        }
        
        ul {
            margin-top: var(--padding-s);
            
            li {
                &:not(:last-child) {
                    margin-bottom: var(--padding-xxs);
                }

                &:before {
                   content: '-';
                    font-size: 2em;
                    line-height: .45;
                }
            }
        }
    }
  
    @media (min-width: $smartphone) {
        &:not(.--no-margin) {
            margin-bottom: var(--padding-xl);
        }

        > div {
            text-align: center;
        }
    }
}

.dropdown {
    position: relative;
    display: inline-block;
    width: 100%;
    overflow: visible !important;
    border: 0 !important;
    cursor: pointer !important;
    
    .dropdown-content {
        @include z-index(1);

        padding-top: var(--padding-xxs);
        
        > div {
            background: var(--primary-lighter);
            border: 1px solid var(--primary-brand);
            border-radius: var(--card-radius);
            overflow: hidden;
            max-height: calc(5.5 * var(--input-height));
            overflow-y: auto;
        }
        
        label {
            // position: relative;
            height: var(--input-height);
            background: var(--primary-lighter);
            color: var(--primary-brand);
            padding: 0 var(--padding-s);
            // left: 0 !important;
            display: block;
            width: 100%;
            // top: 0 !important;
            // transform: none !important;
            // pointer-events: initial;
            cursor: pointer;
            
            @include isCursor() {
                &:hover {
                    background: var(--primary-light);
                }
            }
        }

        input {
            width: 0;
            height: 0;
            padding: 0;
            display: inline;
        }

        input:checked + label {
            background-color: var(--primary-medium);
            color: var(--primary-lighter);
        }
    }
}

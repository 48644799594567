.link {
    @include font-style('body-base');

    position: relative;
    text-transform: uppercase;
    color: currentColor;
    font-weight: bold;

    &::before {
        content: '';
        border-top: 1px solid currentColor;
        width: 100%;
    }
}
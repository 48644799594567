.block-twin {
    --mt-app-height: 80vh; /* Desktop and tablets height. Default: 80vh. */
    --mt-app-height-mobile: 75svh; /* Mobile devices height. Default: 75svh */

    height: var(--mt-app-height);
    background-color: var(--grey);

    @media (max-width: $smartphone) {
        height: var(--mt-app-height-mobile);
    }
}

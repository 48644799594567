@use "sass:math";

#Preloader {
    --color: var(--primary-assertive);
    --bg: var(--primary-color);
    --height-logo: #{math.div(68px, 16px) * 1rem};

    @media (max-width: $smartphone) {
        --height-logo: #{rem(60)};
    }
}

#Preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    
    @include z-index($z-index-loader);
    color: var(--color);
    background-color: var(--bg);
    
    align-items: center;
    flex-direction: column;
    display: flex;
    justify-content: center;

    .logo {
        height: var(--height-logo);
        width: auto;

        svg {
            height: 100%;
            width: auto;
            fill: var(--color);
        }
    }
}

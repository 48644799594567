:root {
    --grey: #999;
    --focus: #ff00ff;
    --color-error: #ff0000;
    --assertive: #7EFF83;
    --black: #041f1e;
    --white: #fff;

    --corporative-text-color: var(--primary-brand) !important;
    --corporative-color: var(--primary-brand) !important;
}

.widget-calendar {
    // --width: #{rem(1020)};

    .section-title {
        color: var(--primary-brand);
    }

    // &__calendar {
    //     width: var(--width);
    // }
}

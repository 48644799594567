@use "sass:math";

.block-iframe {
    --max-width: #{rem(600)};
    --max-width-note: #{rem(550)};
}

.block-iframe {
    > div {
        max-width: var(--max-width);
        margin: 0 auto;
    }
}

.block-iframe > .block-iframe__note {
    @include font-sans();
    font-size: rem(10);
    max-width: var(--max-width-note);
    margin: 2em auto;
    

    a {
        @include basic-a();
        color: currentColor;
        text-decoration: underline;
    }
}
.advantage {
    --card-width: #{rem(180)};
    --icon-width: #{rem(50)};
    --padding-v: var(--padding-m);
    --padding-h: var(--padding-xs);
    
    @media (max-width: $smartphone) {
        --padding-h: 0;
    }
}

.advantage {
    --scale: 1.2;
    
    @include basic-a();
    background-color: var(--aux-light);
    border-radius: var(--card-radius);
    min-height: var(--card-width);
    height: 100%;
    padding: var(--padding-v) var(--padding-h);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    transition: background-color .2s ease-out;
    color: currentColor;

    &:not(.--active) {
        --scale: 1;
        background-color: var(--primary-light);

        svg {
            transition: transform 1s var(--ease-out-quad);
        }
    }

    svg {
        height: var(--icon-width);
        width: var(--icon-width);
        fill: currentColor;
        transform: scale3D(var(--scale),var(--scale),var(--scale));
        transform-origin: center;
        transition: transform .4s var(--ease-out-quad);
        // path {
        //     fill: currentColor;
        // }
    }
}

.block-file {
    --margin: var(--padding-s) auto var(--padding-xl);
    --margin-button: var(--padding-m) auto;

    @media (max-width: $smartphone) {
        --margin: var(--padding-xs) auto var(--padding-l);
        --margin-button: var(--padding-s);
    }

    padding-top: 0;
    padding-bottom: 0;
    margin: var(--margin);
    --align: center !important;

    .block-wysiwyg {
        padding-top: 0;
        padding-bottom: 0;
    }

    .link-pdf {
        margin-top: var(--margin-button);

        a {
            text-decoration: none;
        }
    }
}

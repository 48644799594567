@use "sass:math";

.block-servicios {
    --card-bg: var(--primary-lighter);
    --card-width: #{rem(120)};
    --icon-width: #{rem(50)};
    --cols: 7;
    --service-icon-margin: 0 0 var(--padding-xs);
    --service-padding: var(--padding-s) var(--padding-xs);
    
    &.--secondary {
        --card-bg: var(--aux-medium);
        --card-width: #{rem(180)};
        --icon-width: #{rem(50)};
        --cols: 6;
        --service-padding: var(--padding-s);
    }

    @media (max-width: $smartphone) {
        --cols: 3;
        --icon-width: #{rem(24)};
        --card-width: calc((100vw - var(--gap) * 4)/3);
        --service-padding: var(--padding-xxs) var(--padding-xxs);
        --service-icon-margin: var(--padding-xxs) 0 var(--padding-xxs);

        &.--secondary {
            --card-bg: var(--aux-medium);
            --cols: 3;
            --icon-width: #{rem(24)};
            --card-width: calc((100vw - var(--gap) * 4)/3);
            --service-padding: var(--padding-xxs) var(--padding-xxs);
            --service-icon-margin: var(--padding-xxs) 0 var(--padding-xxs);
        }
    }
}

.block-servicios {
    @include font-sans();

    color: var(--primary-color);
    position: relative;
    text-align: center;
    
    &__content {
        display: grid;
        gap: var(--gap);
        grid-template-columns: repeat(var(--cols), var(--card-width));
    }

    a {
        @include basic-a();
    }

    .servicio {
        background-color: var(--card-bg);
        border-radius: var(--card-radius);
        min-height: var(--card-width);
        height: 100%;
        padding: var(--service-padding);
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center ;
        color: var(--primary-color);

        svg {
            height: var(--icon-width);
            width: var(--icon-width);
            fill: currentColor;
            margin: var(--service-icon-margin);
            
            path {
                fill: currentColor;
            }
        }
    }

    @media (max-width: $tabletLandscape) {
        &.section-default {
            padding-right: 0 !important;
            padding-left: 0 !important;

            .section-title,
            .section-description,
            .block-servicios__content {
                padding-right: var(--padding-s);
                padding-left: var(--padding-s);
            }
        }
    }

    @media (max-width: $smartphone) {
        --height: var(--total-height);

        button {
            span:first-child {
                display: none;
            }
            span:last-child {
                display: block;
            }
        }
        
        &.--hidden {
            --height: calc(2 * (var(--card-width) + var(--gap)));

            button {
                span:first-child {
                    display: block;
                }
                span:last-child {
                    display: none;
                }
            }
        }
        
        &__content {
            height: var(--height);
            overflow: hidden;
            transition: height .2s ease-out;
        }

        .font-style-card-body {
            font-family: var(--card-info-font-family);
            font-weight: var(--card-info-font-weight);
            font-size:  var(--card-info-font-size);
            line-height:  var(--card-info-line-height);
            letter-spacing:  var(--card-info-letter-spacing);
        }
    }

    @media (max-width: $tabletLandscape) {
        &.section-default {
            .block-servicios__content {
                padding-right: var(--gap);
                padding-left: var(--gap);
            }
        }
    }
}

.bar-links {
    position: relative;
    padding: var(--padding-s);

    @media (max-width: $smartphone) {
        display: flex;
        flex-direction: column;
        align-items: center;

        a {
            margin: .46em 0;
        }
    }

    a {
        @include basic-a();
        display: inline-block;
        text-transform: uppercase;
        color: var(--primary-bg);
        margin-left: 1.2em;
        margin-right: 1.2em;

        &.--back {
            &::before {
                content: '↓';
            }
        }
        &:not(.--back) {
            &::before {
                content: '↓';
            }
        }
    }
}
.section-title-icon {
    --text-transform: uppercase;
    --color: var(--primary-color);
    --color-icon: var(--primary-assertive);
    
    text-align: center;
    margin-bottom: var(--padding-l);
    color: var(--color);
    text-transform: var(--text-transform);
    position: relative;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    
    &.--align-left {
        align-items: flex-start;

        + .section-description > div {
            text-align: left;
            margin-left: 0;
        }
    }

    &.--icon {
        padding-top: rem(85);

        span {
            position: relative;
        }
    }
    
    span {
        line-height: .8;
    }
    
    svg {
        height: rem(150);
        width: rem(150);
        position: absolute;
        top: 0;

        path {
            fill: var(--color-icon);
        }

        /*.cls-1 {
            fill: none;
            stroke-miterlimit: 10;
            stroke-width: 5.56px;
            stroke: var(--color-icon);
        }*/
    }

    @media (min-width: $smartphone) {
        margin-bottom: var(--padding-xl);

        &.--icon {
            padding-top: rem(110);
        }

        svg {
            height: rem(200);
            width: rem(200);
        }
    }

    @media (max-width: $smartphone) {
        &.--align-left {
            align-items: center;
        }
    }
}

.section-title-icon__link {
    display: inline-block;
    
    @media (max-width: $smartphone) {
        margin-top: 1em;
    }
}

@use "sass:math";

.button-kora {
    --color1: var(--primary-color);
    --color2: var(--primary-assertive);

    .--palette-secondary & {
        --color1: var(--primary-bg);
    }

    --bg: var(--color1);
    --fill: var(--color1);
    --bg-circle: var(--color2);
    --border-circle: var(--color2);
    --color: var(--color2);
    --color-hover: var(--bg);
    --border: var(--color1);

    --padding: #{rem(8px)}; 
    --height: #{rem(60px)};
    --width: auto;
    --width-close: var(--height);
    --width-open: 155px;
    --width-scrollbar: #{rem(220px)};
    --height-scrollbar: #{rem(8px)};
    
    --circle-size: #{rem(8px)};
    --circle-size-large: #{rem(54px)};
    --circle-size-open: #{rem(38px)};
    --circle-size-close: #{rem(8px)};

    --circle-width: var(--height);
    --circle-scale-open: 1;
    --circle-scale-close: #{math.div(8,54)};
    --circle-scale: var(--circle-scale-close);

    --bg-scale: 1;

    --icon-radius: #{rem(90px)};
    --icon-rotate: -25deg;
    --icon-size: #{rem(32px)};
    --icon-offset: calc((var(--height) - var(--icon-size))/2);
    
    --font-size: var(--font-size-xxsmall);
    --text-padding-left: #{rem(47px)};
    --text-padding-right: #{rem(28px)};
    --text-x: 0;
    --text-x-open: #{rem(10px)};
    --text-x-close: 0;
    
    --opacity-circle: 1;
    --opacity-progress: 0;
    --scale-progress: 1;
    
    @media (max-width: $smartphone) {
        --font-size: var(--font-size-xxxsmall);
    }

    &.--progress {
        --opacity-circle: 0;
        --opacity-progress: 1;

        --color1: var(--primary-brand);
        --color2: var(--primary-lighter);
    }

    &.--light {
        --bg: var(--secondary-superlight);
        --fill: var(--secondary-superlight);
        --bg-circle: var(--primary-color);
        --border-circle: var(--primary-color);
        --color: var(--primary-color);
        --border: var(--primary-color);
    }

    &.--hollow {
        --bg: transparent;
        --bg-circle: transparent;
        --border: var(--primary-color);
        --fill: var(--border);
        --border-circle: var(--border);
        --color: var(--border);
        
        &[data-state="mini"] > .bg {
            border-color: transparent;
        }
    }

    &:not([data-state="mini_text"]).--hollow {
        &:hover {
            --bg-circle: var(--border);
            --fill: var(--secondary-superlight);
            --color-hover: var(--secondary-superlight);
        }
    }

    &.--cta {
        --bg: var(--light-green);
        --fill: var(--light-green);
        --bg-circle: var(--dark-green);
        --border-circle: var(--dark-green);
        --color: var(--dark-green);
        --border: var(--light-green);
    }

    &.--toggle {
        --bg: var(--primary-lighter);
        --fill: var(--primary-lighter);
        --bg-circle: var(--primary-brand);
        --border-circle: var(--primary-brand);
        --color: var(--primary-brand);
        --border: var(--primary-brand);

        &.--active {
            --bg: var(--primary-brand);
            --fill: var(--primary-brand);
            --bg-circle: var(--primary-lighter);
            --border-circle: var(--primary-lighter);
            --color: var(--primary-lighter);
            --border: var(--primary-lighter);
        }
    }

    &.__close {
        --fill: var(--primary-lighter);
        --bg-circle: var(--primary-brand);
        --bg: var(--primary-brand);
        --border-circle: var(--primary-brand);
        --border: var(--primary-brand);
    }

    &.--active {
        pointer-events: none; 
    }
}

a.button-kora {
    display: block
}

.button-kora {
    @include basic-a();
    position: relative;
    padding: var(--padding);
    width: var(--width);
    min-width: var(--width);
    height: var(--height);
    background-color: transparent;
    color: var(--color);
    user-select: none;

    > .bg {
        border: 1px solid var(--border);
        position: absolute;
        width: 100%;
        height: 100%;
        top:0;
        left:0;
        border-radius: var(--height);
        background-color: var(--bg);
        transform-origin: calc(var(--circle-size-open)/2 + var(--padding)/2) center;
        transform: scale3d(var(--bg-scale), var(--bg-scale), var(--bg-scale));
    }

    *,
    * > * {
        pointer-events: none;
    }

    &.--,
    [disabled] {
        pointer-events: none;
    }

    &.--progress > .progress {
        transition:
            opacity .1s var(--ease-out-quad) .2s,
            transform .5s var(--ease-out-quad) .1s;
    }
    &.--progress > .circle-holder {
        transition: opacity .1s var(--ease-in-quad) .2s;
    }

    &:not([data-state="mini"]).--progress:hover {
        --opacity-circle: 1;
        --opacity-progress: 0;
        --scale-progress: 1.4;

        > .progress {
            transition: opacity .1s var(--ease-in-quad) 0.0s,
                        transform .0s var(--ease-out-quad) .2s;
        }
        > .circle-holder {
            transition: opacity .1s var(--ease-in-quad) 0.0s;
        }
    }
}

.button-kora.--scroll-enabled > .scrollbar {
    pointer-events: all;
}
.button-kora > .scrollbar {
    --progress: 0;

    position: absolute;
    top: 0;
    left: calc(var(--circle-width) + var(--padding));
    height: var(--height);
    width: calc(100% - var(--circle-width) * 1.5);
    display: flex;
    align-items: center;
    cursor: pointer;

    > .track {
        position: relative;
        width: 100%;
        height: var(--height-scrollbar);
        overflow: hidden;
        border-radius: var(--height-scrollbar);

        &::before {
            content: '';
            position: absolute;
            top:0;
            left:0;
            width: 100%;
            height: 100%;
            background-color: var(--color);
            opacity: .3;
        }
        
        .track-thumb {
            position: absolute;
            top:0;
            left:0;
            width: calc(100% * var(--progress));
            height: 100%;
            background-color: var(--color);
            border-radius: var(--height-scrollbar);
        }
    }
}

.button-kora > .progress {
    position: absolute;
    top: calc(var(--padding) *.5);
    left: calc(var(--padding) *.5);
    width: calc(var(--circle-width) - var(--padding) * 1);
    height: calc(var(--height) - var(--padding) * 1);
    display: flex;
    align-items: center;
    justify-content: center;

    @include font-style(header);
    font-feature-settings: "tnum" on;
    color: var(--color);
    opacity: var(--opacity-progress);
    transform: scale3d(var(--scale-progress),var(--scale-progress),var(--scale-progress));

    svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        fill: transparent;
        stroke-width: 1px;
        stroke: var(--color);

        .track {
            opacity: .3
        }

        .thumb {
            opacity: 1
        }
    }
}

.button-kora > .circle-holder {
    position: absolute;
    top: 0;
    left: 0;
    width: var(--circle-width);
    height: var(--height);    
    border-radius: var(--height);
    transform: scale3d(var(--circle-scale), var(--circle-scale), var(--circle-scale));
    opacity: var(--opacity-circle);
    pointer-events: none;

    > .circle {
        border: 1px solid var(--border-circle);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: var(--height);
        transform-origin: center;
        background-color: var(--bg-circle);
        overflow: hidden;

        .icon-holder,
        .number-holder {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transform-origin: center var(--icon-radius);
            transform: rotate(-35deg);
        }
        
        .number-holder {
            display: flex;
            align-items: center;
            justify-content: center;
            width: var(--height);
            height: var(--height);
            transform: rotate(0);
        }

        span {
            position: absolute;
            top: var(--icon-offset);
            left: var(--icon-offset);
            height: calc(var(--icon-size) - 2px);
            width: calc(var(--icon-size) - 2px);
            display: block;
            transform-origin: center center;
            
            svg {
                position: absolute;
                top:0;
                left:0;
                height: 100%;
                width: 100%;
                fill: var(--fill);
            }
        }
    }
}

.button-kora > .text-holder {
    @include font-sans();
    position: absolute;
    top:0;
    left:0;
    height: var(--height);
    width: 100%;
    font-size: var(--font-size);
    line-height: var(--height);
    text-transform: uppercase;
    transform: translateX(var(--text-x));
    overflow: hidden;
    pointer-events: none;
    
    > span {
        position: absolute;
        top:0;
        left:0;
        flex: 0 0 auto;
        display: block;
        width: auto;
        height: auto;
        transform-origin: center;
        white-space: nowrap;
        padding: 0 var(--text-padding-right) 0 var(--text-padding-left);

        &.--hover {
            color: var(--color-hover);
        }
    }
}

/* ANIMACION ICON */

@keyframes rectAni1 {
    19% { opacity: 0; }
    20% { opacity: 1; }
    39% { opacity: 1; }
    40% { opacity: 0; }
}

@keyframes rectAni2 {
    0% { opacity: 1;}
    39% { opacity: 1; }
    40% { opacity: 1; }
    59% { opacity: 0; }
    60% { opacity: 1; }
    79% { opacity: 1; }
    80% { opacity: 0; }
    100% { opacity: 0; }
}

@keyframes rectAni3 {
    0% { opacity: 1;}
    59% { opacity: 1; }
    60% { opacity: 0; }
    100% { opacity: 0; }
}

@keyframes rectAni4 {
    59% { opacity: 0; }
    60% { opacity: 1; }
    99% { opacity: 1; }
    100% { opacity: 0; }
}

@keyframes rectAni5 {
    39% { opacity: 0; }
    40% { opacity: 1; }
    59% { opacity: 1; }
    60% { opacity: 0; }
    79% { opacity: 0; }
    80% { opacity: 1; }
    100% { opacity: 1; }
}

@keyframes rectAni6 {
    39% { opacity: 0; }
    40% { opacity: 1; }
    59% { opacity: 1; }
    60% { opacity: 0; }
}

svg.icon-book-ani {
    --time: 10s;
    transition: opacity 0s .2s;

    .__close & {
        opacity: 0;
        transition: opacity 0s .3s;
    }
}

svg.icon-book-ani rect {
    fill: var(--primary-assertive);
    opacity: 0;
   
    &:nth-child(1) { animation: rectAni1 var(--time) infinite; } 
    &:nth-child(2) { animation: rectAni2 var(--time) infinite; } 
    &:nth-child(3) { animation: rectAni3 var(--time) infinite; } 
    &:nth-child(4) { animation: rectAni4 var(--time) infinite; } 
    &:nth-child(5) { animation: rectAni5 var(--time) infinite; } 
    &:nth-child(6) { animation: rectAni6 var(--time) infinite; } 
}

.card-room {
    --radius: var(--card-radius);
    --padding: var(--padding-m) var(--padding-s);    
    --color: var(--primary-color);
    --color-name: var(--primary-color);
    --bg: var(--aux-medium);
    --height: #{rem(400)};
    
    @media (max-width: $smartphone) {
        --width: 100%;
        --height: 0;
        --padding: var(--padding-s);
    }

    &.--dark {
        --color: var(--primary-lighter);
        --color-name: var(--primary-assertive);
        --bg: var(--primary-color);
    }
}

.card-room {
    @include basic-a();

    position: relative;
    border-radius: var(--radius);
    overflow: hidden;

    color: var(--color);
    background-color: var(--bg);

    min-height: var(--height);
    width: var(--width);

    .image {
        position: relative;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }

        a {
            position: absolute;
            top: var(--padding-xs);
            right: var(--padding-xs);
            color: var(--primary-assertive);
        }
    }

    .content {
        padding: var(--padding);
        
        .title {
            text-transform: uppercase;
            color: var(--color-name);
        }

        .description {
            > div {
                @include ellipsis-multiline(5);
            }
        }

        .title,
        .description {
            margin-bottom: var(--padding-s);
        }
        
        .button {
            margin-top: var(--padding-xs);
            width: 100%;
        }

        .list {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            gap: 2%;

            > * {
                min-width: calc(96% / 4);
                align-items: center;
                display: flex;
                margin-bottom: var(--padding-s);

                svg {
                    height: rem(18);
                    max-width: rem(18);
                    min-width: rem(18);
                    margin-right: var(--padding-xxs);
                    fill: var(--primary-color);
                }
            }
        }
    }

    @media (max-width: $smartphone) {
        .image {
            height: rem(200);
        }
    }

    @media (max-width: $tabletLandscape) {
        .image {
            height: rem(250);
            width: 100%;
        }
    }

    @media (min-width: $tabletLandscape) {
        display: flex;

        .image {
            height: 100%;
            width: 50%;
            
            a {
                top: var(--padding-s);
                right: var(--padding-s);
            }
        }
        
        .content {
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center; 
        }
    }
}

@use "sass:math";

.win-message {
    --font-size: var(--font-size-xxsmall);
    --bg-color: var(--primary-brand);
    --color: var(--primary-light);

    &.--light {
      --bg-color: var(--primary-brand);
      --color: var(--primary-light);
    }

    &.__success {
      --bg-color: var(--primary-medium);
      --color: var(--primary-color);
    }

    &.__error {
      --bg-color: #FF0000;
      --color: var(--primary-color);
    }

    &[aria-expanded='false'] {
        visibility: hidden;
        pointer-events: none;
    }
}

.win-message {
    @include z-index($z-index-messages);
    @include font-sans();

    border: 1px solid var(--color);
    background-color: var(--bg-color);
    color: var(--color);
    font-size: var(--font-size);
    opacity: 0;

    .close {
        border: 1px solid var(--color);

        svg path {
            fill: var(--color);
        }            
    }

    .btn {
        border: 1px solid var(--color);
        color: var(--color);

        &.--full {
            color: var(--bg-color);
            background-color: var(--color);
        }
    }

    a {
        color: var(--color);
    }

    &[aria-expanded='false'] {
        visibility: hidden;
        pointer-events: none;
    }
    
    border-radius: rem(200);
    position: fixed;
    left: 0;
    bottom: 0;
    width: calc(100% - 2 * var(--padding-s));
    cursor: auto;
    max-width: rem(790);
    margin: var(--padding-s);
    opacity: 0;

    transform: translate3d(0, 100%, #{$z-index-windows}px);

    display: flex;
    flex-wrap: wrap;
    padding: rem(20px) rem(20px) rem(20px) var(--padding-m);
    justify-content: space-between;
    align-items: center;

    .content {
        flex: 1 0 50%;
        display: block;
        margin-right: var(--padding-xs);
        position: relative;
    }

    .close {
        background-color: transparent;
        border: 1px solid var(--primary-light);
        border-radius: 50%;
        padding: 0;
        height: rem(35);
        width: rem(35);
        min-width: rem(35);
        margin-left: var(--padding-xxs);

        svg {
            height: 100%;
            width: 100%;

            path {
                fill: var(--primary-light);
                transition: .3s ease-out;
            }
        }
    }

    .btn {
        border-radius: rem(200);
        border: 1px solid var(--green);
        background-color: transparent;
        color: var(--green);
        padding: var(--padding-xxs) var(--padding-s);
        min-width: rem(100);

        &.--full {
            color: var(--primary-color);
            background-color: var(--primary-bg);
        }
    }

    a {
        @include basic-a();
        color: var(--green);
    }

    @include isCursor() {
        .btn,
        .close {
            transition: .3s ease-out;

            &:hover {
                background-color: var(--primary-medium);
                color: var(--primary-color);
                opacity: .6;

                svg path {
                    fill: var(--primary-color);
                }
            }
        }
    }

    @media (max-width: $smartphone) {
        border-radius: rem(10);
        display: block;

        padding: rem(20px);

        .btns {
            display: flex;
            text-align: center;
            justify-content: center;
            align-items: center;
            margin-top: rem(20);
            gap: rem(15);
        }

        .btn {
            padding: var(--padding-xxs) var(--padding-xs);
            min-width: 0;
            margin-right: rem(2);
            flex: 1 0 auto;

            &.--full {
                color: var(--bg-color);
                background-color: var(--color);
            }
        }

        .content {
            margin-right: 0;
        }
    }

    @media (min-width: $smartphone) {
        left: 50%;
        transform: translate3d(-50%, 100%, #{$z-index-windows}px);
    }
}

.card-top-rounded {
    --radius: #{rem(20)};
    --width: #{rem(410)};
    --height: #{rem(500)};
    --mask: .2;
    --padding-horizontal: var(--padding-s);

    --color-title: var(--primary-lighter);
    --color-subtitle: var(--primary-assertive);
}

.card-top-rounded {
    position: relative;
    width: var(--width);
    height: var(--height);

    border-top-left-radius: var(--width);
    border-top-right-radius: var(--width);
    border-bottom-left-radius: var(--radius);
    border-bottom-right-radius: var(--radius);

    overflow: hidden;

    > img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

    > .content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 0 var(--padding-horizontal) #{rem(4)};

        text-align: center;
        text-transform: uppercase;

        background: rgba(0, 0, 0, var(--mask));       

        .title {
            color: var(--color-title); 
        }
        .subtitle {            
            margin-top: #{rem(40)};
            color: var(--color-subtitle);
        }
    }
}
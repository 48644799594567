.block-billboard-hero {
    --height: 90vh;
    --opacity-overlay: 0;//.1;
    --padding-top: 0;
    --padding-h: var(--padding-l);
    --border-radius-image: #{rem(450)};
    --image-position: 50% 40%;
    --image-height: var(--height);
    --text-transform: uppercase;
    --text-transform-subtitle: uppercase;
    
    @media (max-width: $smartphone) {
        --height: auto;
        --padding-h: var(--padding-s);
        --image-height: 56vw;
        --padding-top: var(--image-height);
    }

    @media (min-aspect-ratio: 4/2) {
        --image-position: 50% 28%;
    }
}

.block-billboard-hero {
    padding-bottom: var(--padding-xl);

    @media (min-width: $smartphone) {
        &.--title-small {
            .block-billboard-hero__fold {
                h1 {
                    font-size: rem(120);
                    
                }
            }
        }
    }

    &__fold {
        position: relative;
        height: var(--height);
        display: flex;
        align-items: flex-end;
        justify-content: center;

        @media (max-width: $smartphone) {
            flex-direction: column;
            padding-top: var(--image-height);
            padding-bottom: 0;
        }

        figure {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: var(--image-height);
            pointer-events: none;
            background-color: var(--primary-color);
            
            img {
                border-top-right-radius: var(--border-radius-image);
            }

            img,
            video {
                height: 100%;
                width: 100%;
                object-fit: cover;
                object-position: var(--image-position);
            }

            &::after {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: var(--height);
                background-color: rgba(0, 0, 0, var(--opacity-overlay));
            }
        }

        h1 {
            --title-hotel-h1-line-height: .9;
            color: var(--primary-assertive);
            text-align: center;
            position: relative;
            text-transform: var(--text-transform);
        }
    }
    
    &__content {
        padding: var(--padding-s) var(--padding-xs);
        text-align: center;

        @media (max-width: $smartphone) {
            padding-bottom: 0;
        }

        p {
            color: var(--primary-color);
            margin: 0 auto;
            max-width: rem(936);
        }

        .subtitle {
            
            text-transform: var(--text-transform-subtitle);

            @media (max-width: $smartphone) {
                hyphens: auto;
            }
        }
    }
    
    &__text-top {
        @include text-shadow-body();

        max-width: calc(2 * var(--padding-h) + rem(385));
        width: 100%;
        position: absolute;
        left: 0;
        padding: 0 var(--padding-h);
        top: 30%;
        color: var(--primary-lighter);

        @media (max-width: $smartphone) {
            position: relative;
            top: 0;
            text-shadow: none;
            color: var(--primary-color);
            margin: var(--padding-s) 0 0;
            order: 2;
        }
    }

    h1 {
        @media (max-width: $smartphone) {
            order: 1;
            margin-top: -1.2em;
        }
    }

    &__text {
        padding: var(--padding-s) var(--padding-xs);
        max-width: rem(700);
        margin: var(--padding-xl) auto 0;

        @media (max-width: $smartphone) {
            padding: var(--padding-xs) var(--padding-m);
            margin: var(--padding-s) auto 0;
        }
    }

    @media (max-width: $smartphone) {
        &__fold {
            padding: var(--padding-top) var(--padding-xs) var(--padding-xs);
        }
    }

    @media (min-width: $smartphone) {
        padding-bottom: var(--padding-xxxl);

        &__fold {
            h1 {
                top: rem(80);
            }
        }
        
        &__content {
            padding: calc(#{rem(80)} + var(--padding-xs)) var(--padding-xs) var(--padding-xs);
        }

        &__text {
            column-count: 2;
            margin-top: var(--padding-xxl);
        }

         &__text-top {
            top: 50%;
        }
    }
}

$z-index-bg: 0;

$z-index-3D: 1;
$z-index-wrap: 2;
$z-index-goto-main: 4;
$z-index-progress: 5;
$z-index-categories: 6;
$z-index-footer: 10;
$z-index-cursor: 10;
$z-index-header: 10;
$z-index-toggle: 11;
$z-index-scrollbar: 11;
$z-index-scroll-top: 11;
$z-index-interface: 11;
$z-index-windows: 12;
$z-index-sidemenu: 12;
$z-index-messages: 13;

$z-index-book-button: 15;

$z-index-chatbot: 16;
$z-index-book-win-mobile: 17;
$z-index-gallery: 18;
$z-index-cookies: 18;
$z-index-loader: 19;
$z-index-tweakpane: 20;

$z-index-reserva: 6;

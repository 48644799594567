@use "sass:math";

.block-vocabulario {
    --min-height: #{rem(150)};
    --width: #{rem(525)};
    
    @media (max-width: $smartphone) {
        --width: 100%;
    }
}

.block-vocabulario {
    .section-title {
        text-align: left;
    }

    .vocabulary {
        border-radius: var(--block-radius);
        background-color: var(--aux-light);
        padding: var(--padding-s);
        min-height: var(--min-height);
        width: var(--width);

        h3 {
            margin-bottom: var(--padding-s);
        }
    }

    &__items {
        li {
            margin-bottom: var(--padding-s);
        }
    }

    @media (max-width: $smartphone) {
        &__content {
            margin-bottom: var(--padding-m);
        }
    }

    @media (max-width: $tabletPortrait) {
        &__content {
            margin-bottom: var(--padding-xl);
        }
    }

    @media (min-width: $smartphone) {
        > * {
            width: 75%;
        }

        &__content {
            position: sticky;
            top: var(--header-total);
        }

        .vocabulary {
            padding: var(--padding-m) var(--padding-s);
        }

        &__content {
            padding-right: var(--padding-m);
        }

        &__items {
            li {
                margin-bottom: var(--padding-m);
            }
        }
    }

    @media (min-width: $tabletPortrait) {
        display: flex;
        align-items: flex-start;

        > * {
            flex: 0 1 50%;
        }

        &__content {
            position: sticky;
            top: calc(50vh - 1em);
        }

        &__text {
            margin-bottom: var(--padding-m);
        }

        &__items {
            padding-left: var(--padding-m);
        }
    }
}

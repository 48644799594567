@mixin button {
    --color: var(--primary-color);
    --color-hover: var(--primary-assertive);
    --bg: var(--primary-assertive);
    --bg-hover: var(--primary-color);
    --height: #{rem(50)};
    --padding-vertical: #{rem(1)};
    --padding-horizontal: #{rem(40)};
    
    .--palette-secondary & {
        --color: var(--primary-bg);
        --color-hover: var(--primary-assertive);
        --bg-hover: var(--primary-brand);
    }

    @include basic-a();
    @include font-style('button');

    position: relative;
    height: var(--height);
    display: flex;
    align-items: center;
    appearance: none;
    justify-content: center;

    border-radius: var(--height);
    background: var(--bg);
    color: var(--color);
    cursor: pointer;
    
    padding: var(--padding-vertical) var(--padding-horizontal);
    
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    vertical-align: top;

    * { pointer-events: none; }
    span { display: block; }

    .icon {
        height: #{rem(32)};
        width: #{rem(32)};

        svg {
            fill: currentColor;
        }
    }
    
    transition: all .2s ease-out;
    
    @include isCursor() {
        &:hover {
            color: var(--color-hover);
            background-color: var(--bg-hover);
        }
    }
    
    &.--light {
        --color-hover: var(--primary-color);
        --bg-hover: var(--primary-light);
    }

    &.--dark {
        --color: var(--primary-assertive);
        --bg: var(--primary-color);
        --color-hover: var(--primary-color);
        --bg-hover: var(--primary-light);
    }

    &.--round {
        width: var(--height);
        padding: 0;
    }
}

.button {
    @include button;
}
.widget-estudios {
    --height: 90vh;
    --padding-v: var(--padding-xl);
    --padding-v-bottom: var(--padding-s);
    --padding-h: var(--padding-l);
    --bg: var(--primary-lighter);
    --cols: 2;
    --opacity-overlay: .2;
    --text-title-transform: uppercase;
    --text-transform: uppercase;
    
    @media (max-width: $smartphone) {
        --height: 110vw;
        --padding-v: var(--padding-m);
        --padding-v-bottom: var(--padding-xs);
        --padding-h: var(--padding-xs);
        --opacity-overlay: .2;
        --cols: 1;
    }
}

.widget-estudios {
    background-color: var(--bg);

    &__fold {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        height: var(--height);
        padding: calc(var(--header-total) + var(--padding-v)) var(--padding-h) var(--padding-v-bottom);
        
        > div {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            height: 100%;
        }
        
        figure {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: var(--height);
            pointer-events: none;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }

            &::after {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, var(--opacity-overlay));
            }
        }

        .subtitle,
        .title {
            --title-hotel-h1-line-height: .9;
            color: var(--primary-bg);
            text-align: center;
            position: relative;
            max-width: rem(1120);
            @include text-shadow-body();
        }

        .title {
            text-transform: var(--text-title-transform);
        }

        .subtitle {
            margin-bottom: var(--padding-xs);
            text-transform: var(--text-transform);
        }

        .tab-selector {
            margin-bottom: var(--padding-m);
            background-color: rgba(0, 0, 0, .1);
        }

        button {
            @include basic-a();
            padding: 0;
            background-color: transparent;
            text-decoration: underline;
        }
    }

    &__content {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    &__book {
        position: relative;
        display: flex;
        justify-content: center;
        text-align: center;
        flex-direction: column;
        color: var(--primary-bg);

        .book-banner-mirai {
            margin-bottom: var(--padding-s);
        }

        &__note {
            padding: 0 var(--padding-xs);
            @include text-shadow-body();
        }
        &__note strong {
            position: relative;
            --width: 100%;
            --height: 1.8em;
            --height-offset: -.3em;

            display: inline-block;
            padding: 0 calc(var(--height)*.4);
            margin: 0 0;

            &::before{
                content: "";
                white-space: pre;
            }
            
            &::after {
                position: absolute;
                content: '';
                top:50%;
                left:0;
                width: var(--width);
                height: var(--height);
                z-index: 0;
                transform: translateY(-50%);
                border-radius: var(--height);
                border: 1px solid var(--primary-assertive);
                color: var(--primary-color);
            }
        }
    }

    &__list {
        padding: 0 var(--padding-h);
    }

    @media (max-width: $smartphone) {
        padding-bottom: var(--padding-xl);

        &__fold {
            padding: var(--padding-xl) var(--padding-xs) var(--padding-xs);
        }
        
        &__book {
            .book-banner-mirai {
                display: none;
            }

            &__note strong {
                position: relative;
                --height: 1.4em;
            }
           
        }

        &__list {
            .card-room:not(:last-child) {
                margin-bottom: var(--padding-m);
            }
        }
    }

    @media (min-width: $smartphone) {
        padding-bottom: var(--padding-xxxl);

        &__list {
            display: flex;
            flex-wrap: wrap;
            gap: var(--gap);

            .card-room {
                --width: calc(100% / var(--cols) - var(--gap) * (var(--cols) - 1) / var(--cols));
            }
        }
    }
}

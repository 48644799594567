.card-540-400 {
    --default-width: #{rem(400)};
    --default-height: #{rem(540)};

    --radius: var(--card-radius);
    --radius-inside: #{rem(15)};
    --width: var(--default-width);
    --height: var(--default-height);
    --padding-v: var(--padding-s);
    --padding-h: var(--padding-s);

    --width-figure: calc(var(--width) - var(--padding-v) * 2);
    --height-figure: var(--width-figure);

    --height-content: calc(var(--height) - var(--height-figure) - var(--padding) * 2);

    --color: var(--primary-color);

    &.--type-1 { --bg: var(--aux-light); }
    &.--type-2 { --bg: var(--aux-medium); }
    &.--type-3 { --bg: var(--aux-dark); }

    @media (max-width: $smartphone) {
        --padding-h: var(--padding-xs);
        --width: calc(var(--default-width) * .75);
        --height: calc(var(--default-height) * .75);
    }
}

.card-540-400 {
    @include basic-a();

    position: relative;
    width: var(--width);
    height: var(--height);
    border-radius: var(--radius);
    padding: var(--padding-v) var(--padding-h);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    color: var(--color);
    background-color: var(--bg);

    > .content {
        height: var(--height-content);
        // padding: 0 var(--padding);
        text-align: center;
       
        .title {
            text-transform: uppercase;
            color: var(--color); 
            margin-bottom: var(--padding-xs);
        }

        .subtitle {            
            color: var(--color);
        }
    }
    > figure {
        position: relative;
        width:  var(--width-figure);
        height: var(--height-figure);
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
}

.card-540-400.--type-1 {
    > figure {
        border-radius: 50%;
    }
}

.card-540-400.--type-2 {
    > figure {
        border-top-right-radius: var(--width-figure);
        border-top-left-radius: var(--width-figure);
        border-bottom-right-radius: #{rem(30)};
        border-bottom-left-radius: #{rem(30)};
    }
}

.card-540-400.--type-3 {
    > figure {
        border-bottom-right-radius: var(--width-figure);
        border-bottom-left-radius: var(--width-figure);
        border-top-right-radius: #{rem(30)};
        border-top-left-radius: #{rem(30)};
    }
}


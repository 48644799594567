.block-billboard-cards {
    --height: 100vh;
    --padding-h: var(--padding-l);
    --padding-v: var(--padding-xxxl);
    --opacity-overlay: .2;
    --opacity-overlay-split: .1;
    --image-position: 50% 40%;
    --text-transform: uppercase;
    --padding-card-v: var(--padding-s);
    --padding-card-h: var(--padding-s);
    --margin-text: var(--padding-xl);

    &.--dark {
        --opacity-overlay: .38;
    }
    
    @media (max-width: $smartphone) {
        --padding-h: var(--padding-s);
        --padding-v: var(--padding-xxl);
    }

    @media (max-width: $tabletPortrait) {
        --height: 80vh;
    }

    @media (min-aspect-ratio: 4/2) {
        --image-position: 50% 20%;
    }

    &.--rewards {
        --text-transform: none;
        --height: 20vh !important;
        --padding-v: var(--padding-l) !important;
        --margin-text: var(--padding-l) !important;

        h1 {
            letter-spacing: -0.04em;
            margin-bottom: .4em;
        }
    }
}

.block-billboard-cards {
    &__fold {
        display: flex;
        align-items: center;
        justify-content: center;

        position: relative;
        min-height: var(--height);
        padding: calc(var(--header-total) + var(--padding-v)) var(--padding-h) var(--padding-v);

        > div {
            margin-bottom: var(--margin-text);
        }

        figure {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
            background-color: var(--primary-color);

            video,
            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                object-position: var(--image-position);
            }

            &::after {
                content: '';
                background-color: rgba(0, 0, 0, var(--opacity-overlay));
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
            }
        }

        h1 {
            --title-hotel-h1-line-height: .9;
            color: var(--primary-lighter);
            text-align: center;
            position: relative;
            text-transform: var(--text-transform);
            margin: 0 auto var(--padding-v);

            &:last-child {
                margin-bottom: 0;
            }

            strong {
                color: var(--primary-assertive);
                font-weight: 800;
            }
        }

        h1:has(+ .subtitle) {
            margin: 0;
        }

        .subtitle {
            position: relative;
            color: var(--primary-lighter);
            text-align: center;
            margin: var(--padding-xs) auto var(--padding-xxxl);
        }
    }

    &__text {
        max-width: rem(705);
        position: relative;
        color: var(--primary-lighter);
        margin: 0 auto;

        p:not(:last-child) {
            margin-bottom: var(--padding-s);
        }
    }

    &__split {
        margin-top: calc(-1 * var(--padding-v));
        padding: 0 var(--padding-h) var(--padding-m);

        .split,
        .card-reward-tier {
            min-height: 100%;

            b,strong {
                font-weight: 800;
            }
        }
    }

    &__center {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: var(--padding-s);
        padding: 0 var(--padding-s);
        text-align: center;

        > a {
            flex: 0 1 auto;
        }

        p {
            margin-top: 1.18em;
        }
    }
    
    @media (max-width: $smartphone) {
        &__fold {
            h1 {
                margin: 0 auto var(--padding-v);
            }
        }

        &__split {
            display: flex;
            gap: var(--gap);

            > div {
                flex: 1 0 80vw;
            }
            @include scroll-horizontal();
        }
    }
    
    @media (min-width: $smartphone) {
        &__text {
            gap: var(--gap);
            column-count: 2;
        }

        &__split {
            display: flex;
            gap: var(--gap);

            > div {
                width: calc(50% - var(--gap) / 3);
            }
        }
    }
}

@use "sass:math";

.block-rewards-signup {
    --max-width: #{math.div(796px, 16px) * 1rem};
    --max-width-body: #{math.div(540px, 16px) * 1rem};
    --icon-size: #{math.div(12px, 16px) * 1rem};
}

.block-rewards-signup {
    @include font-sans();
    color: var(--primary-color);
    
    .mi-club-signup__title + p {
        display: none;
    }

    .mi-club-signup {
        padding: 0;
        max-width: var(--max-width);

        > img {
            display: none;
        }
    }
}

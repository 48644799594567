.widget-cards {
    --gap: var(--padding-m);

    @media (max-width: $smartphone) {
        display: none !important;
    }
}

.widget-cards {
    padding: 25vh var(--gap);
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: var(--gap);
}

#Sidemenu {
    --bg: var(--primary-color);
    --color: var(--primary-bg);
    --font-size-toggle: var(--font-size-xxxsmall);
    --padding-top: 0;
    --padding-bottom: var(--padding-l);
    --padding-h: var(--padding-m);
    --max-width: #{rem(424)};
    
    @media (max-width: $smartphone) {
        --font-size-toggle: var(--font-size-xxxsmall);
        --padding-top: 28vw;
        --padding-bottom: 40vw;
        --padding-h: var(--padding-s);
        --max-width: 100%;
    }
}

#Sidemenu {
    color: var(--color);
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;

    @include z-index($z-index-sidemenu);

    &[aria-expanded='false'] {
        visibility: hidden;
        pointer-events: none;
    }

    .sidemenu__toggle-button {
        display: none;
    }

    > .fake-button {
        @include z-index(0);

        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: black;
    }

    .toggle-menu {
        @include font-sans();

        font-size: var(--font-size-toggle);
        color: inherit;
        background-color: transparent;
        padding: 0;
        text-transform: uppercase;    
    }

    > .content {
        background-color: var(--bg);
        height: calc(var(--vh, 1vh) * 100);
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        max-width: var(--max-width);

        @include z-index(1);
        
        > div {
            min-height: 100%;
            padding: var(--padding-top) var(--padding-h) var(--padding-bottom);
            
            overflow-y: auto;
            overflow-x: hidden;
            -webkit-overflow-scrolling: touch;
        }
    }

    .links {
        margin: var(--padding-m) 0 var(--padding-l);

        a {
            display: flex;
            margin-bottom: .42em;
        }
    }

    .langs {
        --size-icon: 1.2em;

        display: flex;
        gap: var(--padding-xs);
        justify-content: center;

        position: relative;
        padding: 0 var(--padding-h);
        margin-bottom: var(--padding-s);

        > .icon {
            width: var(--size-icon);
            height: var(--size-icon);
            fill: currentColor;
        }

        a {
            margin: 0 var(--padding-xxs);
            text-transform: uppercase;
            font-weight: 700;

            &:not(.is-active) {
                opacity: .6;
            }

            .is-active {
                pointer-events: none;
            }
        }
    }

    .nav-item {
        @include basic-a();
        --title-hotel-h5b-font-size: #{rem(18)};

        color: currentColor;
        display: inline-block;
        overflow: hidden;

        &[aria-current="page"] {
            .__text { font-weight: bold; }
        }

        &[aria-current="page"],
        &:hover {
            .__text::before {
                background-color: var(--white);
                color: var(--primary-color);
            }
        }

        &.--has-subitems {
            pointer-events: none;
        }

        .__text {
            display: flex;
            align-items: center;
            padding: var(--padding-xs) 0;
            position: relative;

            &::before {
                content: counter(list);
                @include font-sans();
                font-size: .6em;
                min-width: 2em;
                width: 2em;
                height: 2em;
                border-radius: 50%;
                border: 1px solid currentColor;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 1.1em;
            }
        }
    }

    .nav-subitem {
        @include basic-a();
        --title-hotel-h5b-font-size: #{rem(18)};    
        color: currentColor;
        display: inline-block;
        overflow: hidden;

        &[aria-current="page"] {
            .__text { font-weight: bold; }
        }

        &[aria-current="page"],
        &:hover {
            .__text::before {
                background-color: var(--white);
                color: var(--primary-color);
            }
        }

        .__text {
            display: flex;
            align-items: center;
            padding: var(--padding-xs) 0;
            position: relative;

            &::before {
                content: "";
                @include font-sans();
                width: .5em;
                height: .5em;
                border-radius: 50%;
                border: 1px solid currentColor;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: .4em;
            }
        }
    }

    .list {
        counter-reset: list;
    }

    .list > li {
        position: relative;
        counter-increment: list;
    }

    .list > li > ul {
        display: block;
        width: 100%;
        padding-left: var(--padding-m);
    }


    @media (min-width: $smartphone) {
        .langs {
            display: none;
        }
        .top {
            display: flex;
        }

        .sidemenu__toggle-button {
            display: block;
            position: absolute;
            top: var(--padding-m);
            right: var(--padding-m);
        }
    }
}
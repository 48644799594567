//↗ → ← ↖

@mixin link-small() {
    --size-icon: 1.2em;
    --top-icon: calc((var(--bar-height) - var(--size-icon)) / 2);
    --offset-icon: 0;

    &.--with-icon {
        --offset-icon: calc(var(--size-icon) + .45em);
    }

    @include basic-a();

    position: relative;
    display: inline-flex;
    color: inherit;
    align-items: center;

    .icon {
        width: var(--size-icon);
        height: var(--size-icon);
        fill: currentColor;
        margin-right: var(--padding-xs);
    }
    
    @media (min-width: $smartphone) {
        height: var(--bar-height);
        padding-left: var(--offset-icon);

        .icon {
            position: absolute;
            top: var(--top-icon);
            left: 0;
        }
    }
}

.link-small {
    @include link-small();
}

.section-fullscreen {
    --padding-v: var(--padding-m);
    --padding-v-bottom: var(--padding-m);
    --padding-h: var(--padding-l);
    
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-l);
        --padding-v-bottom: var(--padding-m);
        --padding-h: var(--padding-xs);
    }

    &.--aux-medium {
        --bg: var(--aux-medium);
    }

    &:first-child {
        --padding-v: calc(var(--header-height) + var(--padding-xl));
        
        @media (max-width: $smartphone) {
            --padding-v: calc(var(--header-height) + var(--padding-m));
        }
    }
}

.section-fullscreen {
    position: relative;
    padding: var(--padding-v) var(--padding-h) var(--padding-v-bottom); 

    &:not(.--round) {
        background-color: var(--bg);
    }

    &.--round::before {
        content: '';
        position: absolute;
        top:0;
        left:8%;
        width: 84%;
        height: 100%;
        background-color: var(--bg);
        border-radius: var(--card-radius);
    }

    &.--no-padding {
        padding: var(--padding-v) 0 var(--padding-v-bottom); 
    }
    
    &__wrapper {
        display: flex;
        justify-content: center;
    }

    &__footer {
        display: flex;
        justify-content: center;
        margin-top: var(--padding-m);
        gap: var(--padding-xs);
        flex-basis: 100%;
        width: 100%;
    }
}

.widget-experiencias-full {
    --padding-v: var(--padding-xl);
    --padding-h: var(--padding-l);
    
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-l);
        --padding-h: var(--padding-s);
    }

    .default-slider {
        --padding-h: var(--padding-l);
        --padding-h-holder: var(--padding-l);
        --item-height: auto;
        --item-width: #{rem(750)};
    }
    
    @media (max-width: $smartphone) {
        .default-slider {
            --padding-h: var(--padding-xs);
            --padding-h-holder: var(--padding-h);
            --item-width: 82vw;
        }
    }
}

.widget-experiencias-full {
    position: relative;

    .link-pdf {
        margin-top: -3rem;
        margin-bottom: var(--padding-m);
    }

    &__block {
        padding: var(--padding-v) 0;
        width: 100%;

        &:first-child {
            padding-top: 0;
        }

        &:nth-child(even) {
            background-color: var(--aux-light);
        }

        > h2 , > h1 {
            max-width: rem(900);
            margin-bottom: .7em;
        }

        > .text {
            max-width: rem(600);
            margin-bottom: var(--padding-m);
        }

        > h2 , > h1, > .text, > .categ-image {
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            padding-right: var(--padding-h);
            padding-left: var(--padding-h);
        }

        p {
            margin-bottom: 1em;
            &:last-of-type { margin-bottom: 0; }
        }

        .categ-image {
            max-width: rem(800);

            figure {
                border-radius: var(--card-radius);
                overflow: hidden;
            }

            img {
                width: 100%;
                height: auto;
            }
        }
    }

    @media (max-width: $smartphone) {
        .link-pdf {
            margin-top: -1.5rem;
            margin-bottom: var(--padding-m);

            a {
                font-size: var(--font-size-xxxxsmall);
            }
        }

        &__header {
            margin-bottom: var(--padding-l);
        }

        .__touch & {
            .section-default__footer {
                display: none;
            }
        }

        .default-slider__item {
            height: auto;
            flex: 1;

            > .card-experience {
                --height: 100%;
            }
        }
    }
}

.card-reward-tier {
    --icon-size: #{rem(32)};
    

    --padding-top: calc(var(--header-height));
    --padding-bottom: var(--padding-m);
    --padding-h: var(--padding-m);
    --padding-left: 0;
    --padding-right: var(--padding-l);
    --width-bg: 50%;
    
    --padding-card-v: var(--padding-s);
    --padding-card-h: var(--padding-s);
    --min-height: 60vh;
    --opacity-overlay: .42;

    --color-left: var(--primary-lighter);
    
    @media (max-width: $smartphone) {
        --padding-top: 80vw;
        --color-left: inherit;
        --padding-left: var(--padding-xs);
        --padding-bottom: var(--padding-xs) !important;
        --padding-right: 0;
        --min-height: 10px;
        --max-width-left: 400px;
        --opacity-overlay: .1;
    }
}

.card-reward-tier {
    position: relative;
    background-color: var(--primary-lighter);
    border-radius: var(--card-radius);
    width: 100%;
    
    padding: var(--padding-card-v) var(--padding-card-h);
    text-align: center;

    @media (max-width: $smartphone) {
        margin-top: 0;
    }

    > a {
        margin-top: var(--padding-l);
    }

    .subtitle {
        margin-top: 0.4em;
    }

    .main-reward {
        text-align: center;
        margin: .4em auto 2.13em;
        padding: .2em 1em;
        border-radius: 1em;
        background-color: var(--primary-assertive);
        width: auto;
        display: inline-block;
    }

    ul {
        list-style: none;
        counter-reset: list;
        padding: 0;

        li {
            position: relative;
            display: flex;
            align-items: center;
            counter-increment: list;
            width: 100%;
            margin: .3em 0;
            text-align: left;

            display: flex;
             
            .icon {
                position: relative;
                flex: 0 0 var(--icon-size);
                width: var(--icon-size);
                height: var(--icon-size);
                border: 1px solid var(--primary-assertive);
                border-radius: var(--icon-size);

                svg {
                    position: absolute;
                    top:20%;
                    left:20%;
                    width: 60%;
                    height: 60%;
                    object-fit: contain;
                    object-position: center;
                    fill: var(--primary-assertive);
                }
            }

            .text {
                margin-left: .45em;
            }
        }
    }
}

.block-ubicacion {
    --map-width: 147%;
    --map-offset-left: -28%;

    @include font-sans();

    color: var(--primary-assertive);
    text-align: center;
    position: relative;
    
    &__wrapper {
        background-color: var(--primary-color);
        border-radius: var(--block-radius);
        justify-content: center;
        padding: var(--padding-m);
        position: relative;
        overflow: hidden;
    }
    
    &__header {
        margin-bottom: var(--padding-m);

        h2 {
            color: var(--primary-fluor);
        }

        p {
            color: var(--secondary-superlight);
        }
    }
    
    &__footer {
        justify-content: center;
        display: flex;
        margin-top: var(--padding-m);
    }

    .map {
        a {
            display: block;
            @include basic-a();
        }

        img,
        svg {
            height: auto;
            width: 100%;
        }
    }

    @media (max-width: $smartphone) {
        &__wrapper {
            padding: var(--padding-s);
        }

        &__content {
            display: flex;
            flex-direction: column-reverse;

            .image {
                margin-top: var(--padding-s);
                border-radius: var(--card-radius);
                overflow: hidden;
            }
        }

        &__header {
            h2 {
                margin-bottom: .42em;
            }
        }

        .map {
            img,
            svg {
                width: var(--map-width);
                margin-left: var(--map-offset-left);
            }
        }
    }
    
    @media (min-width: $smartphone) {
        &__content {
            align-items: center;
            display: flex;
            gap: var(--gap);

            .map {
                flex: 0 1 60%;
            }

            .image {
                flex-grow: 1;
                border-radius: var(--card-radius);
                overflow: hidden;
            }
        }
        
        &__header {
            margin-bottom: var(--padding-l);
        }

        &__footer {
            margin-top: var(--padding-l);
        }
    }
}

@use "sass:math";

.block-gallery-mirai {
    --height: #{rem(600)};
    --max-height: #{rem(600)};
    --width-slide: #{rem(700)};
    --bg: var(--primary-light);
    
    @media (max-width: $smartphone) {
        --height: #{rem(250)};
        --max-height: #{rem(250)};
        --width-slide: #{rem(300)};
    }
}

.block-gallery-mirai {
    position: relative;
    background-color: var(--bg);

    &__slider {
        position: relative;
        display: flex;
        flex-direction: column;
        user-select: none;
        cursor: grab; 

        &:active {
            cursor: grabbing;
        }

        > .holder {
            order: 1;
            position: relative;
            height: var(--height);
            display: flex;
            flex-wrap: nowrap;
            overflow: hidden;
        }
    }
    
    &__item {
        position: relative;
        overflow: hidden;
        display: flex;
        align-items: flex-start;
        
        height: var(--height);
        flex: 0 0 var(--width-slide);
        width: var(--width-slide);
        margin-left: var(--gap);

        &:first-child,
        &:last-child {
            flex: 0 0 calc(var(--width-slide) + var(--gap));
            width: calc(var(--width-slide) + var(--gap));
        }

        &:first-child {
            img {
                left: var(--gap);
            }
        }
            
        &:last-child {
            img {
                width: calc(100% - var(--gap));
            }
        }

        figure {
            height: 100%;
            width: 100%;
        }

        img {
            object-fit: contain;
            object-position: center;
            -webkit-user-drag: none;
            -khtml-user-drag: none;
            -moz-user-drag: none;
            -o-user-drag: none;
            user-drag: none;

            left: 0;
            padding: 0;
            position: absolute;
            top: 0;
            // right: 0;
            // bottom: 0;
            height: 100%;
            width: 100%;
        }
    }

    &__text {
        max-width: rem(780);
        margin: 0 auto var(--padding-xxl);
        padding: 0 var(--padding-h);
    }

    // @media (max-width: $smartphone) {

    // }
}

.includes-list {
    --padding-h: var(--padding-l);
    
    @media (max-width: $smartphone) {
        --padding-h: var(--padding-xs);
    }
}

.includes-list {
    background-color: var(--primary-lighter);
    padding: var(--padding-m) var(--padding-h);
    
    &__wrapper {
        border-radius: var(--card-radius);
        background-color: var(--primary-light);
        padding: var(--padding-s) 0;
    }

    span {
        color: var(--primary-brand);
        display: block;
        margin-bottom: var(--padding-xs);
    }

    h2 {
        margin-bottom: var(--padding-s);
    }

    [data-acordion-toggle] {
        padding-right: 0;
    }

    &__list {
        ul {
            height: 100%;
            column-count: 1;
        }

        li {
            padding-bottom: .72em;
            position: relative;
            color: var(--primary-color);

            &:not(.--headline) {
                padding-left: var(--padding-xs);
                &::after {
                    content: '';
                    background-color: var(--primary-color);
                    border-radius: 50%;
                    height: rem(4);
                    width: rem(4);
                    position: absolute;
                    left: rem(0);
                    top: rem(8);
                }
            }

            &.--headline + li {
            //    margin-bottom: 2em;
            }
        }
    }

    @media (max-width: $smartphone) {
        h2 {
            hyphens: auto;
        }
        
        &__wrapper {
            > div:first-child {
                padding: 0 var(--padding-s);
                margin-bottom: var(--padding-l);
            }
    
            > div:last-child {
                display: flex;
                justify-content: flex-start;
                overflow-x: auto;
                padding: 0 var(--padding-s);
            }
        }

        &__list {
            &.--multiple {
                ul {
                    li:first-child {
                        padding-left: 0;
                        &::after {
                            display: none;
                        }
                    }
                }
            }
        }

        [data-acordion-toggle] {
            margin-bottom: var(--padding-m);
        }

        [data-acordion-group] {
            padding-bottom: var(--padding-m);
        }
    }

    @media (min-width: $smartphone) {
        padding: var(--padding-l) var(--padding-h);

        &__wrapper {
            padding: var(--padding-m);
            display: flex;
            justify-content: space-between;
            gap: var(--padding-xxl);
            
            > div:first-child {
                width: rem(420);
            }
            
            > div:last-child {
                width: calc(100% - var(--padding-xxl) - #{rem(420)});
                overflow-x: auto;
            }
        }

        &__list {
            ul {
                column-count: 3;
                min-width: rem(650);
            }

            &.--multiple {
                width: 100%;
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                overflow: hidden;
    
                ul {
                    flex: 0 0 33.33%;
                    column-count: 1;
                    min-width: 0px;
    
                    li:first-child {
                        padding-left: 0;
                        &::after {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    @media (min-width: $tabletLandscape) {
        &__wrapper {
            gap: var(--padding-xxxxl);

            > div:first-child {
                width: rem(420);
            }
            
            > div:last-child {
                width: calc(100% - var(--padding-xxxxl) - #{rem(420)});
            }
        }
    }
}

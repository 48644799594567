.card-offer-large {
    --radius: var(--card-radius);
    --radius-inside: #{rem(12)};
    --width: #{rem(730)};
    --height: #{rem(400)};
    --padding: var(--padding-s);
    --color: var(--white);
    --color-offer: var(--primary-assertive);
    --bg: var(--primary-color);
    --mask: .32;
}

.card-offer-large {
    @include basic-a();

    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--padding-s);

    padding: var(--padding);
    width: var(--width);
    min-width: var(--width);
    height: var(--height);
    
    border-radius: var(--radius);
    background-color: var(--bg);

    > figure {
        position: relative;
        border-radius: var(--radius-inside);
        overflow: hidden;

        > img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
      
    > .content {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 1rem;          
        color: var(--color);
        
        > .title {
            text-transform: uppercase;
            &::first-line {
                @include font-style(serif-small);
            }
        }

        > .subtitle {
            text-transform: uppercase;
        }

        > .offer {
            width: #{rem(90)};
            height: #{rem(90)};
            border-radius: 50%;
            border: 1px solid currentColor;
            padding-top: .48em;
            text-align: center;

            &:empty {
                opacity: 0;
            }
        }

        > .button {
            position: absolute;
            left: 0;
            bottom: 0;
        }
    }
}


@use "sass:math";

.block-experiencias {
    --text-width: #{rem(440)};
    --title-hotel-h1-font-size: #{rem(85)};
    --margin-text-v: var(--padding-xxxl);
    

    @media (max-width: $tabletPortrait) {
        --title-hotel-h1-font-size: #{rem(40)};
    }

    @media (max-width: $smartphone) {
        --text-width: 100%;
        --title-hotel-h1-font-size: #{rem(25)};
        --margin-text-v: var(--padding-s);
    }
}

.block-experiencias {
    @include font-sans();

    h2 {
        --title-hotel-h1-line-height: .7;
        @include z-index(2);
        position: relative;
        text-align: center;
        
        strong {
            color: var(--primary-lighter);
            --title-hotel-h1-line-height: .6;
            @include font-style('title-hotel-h1');
        }
    }

    .wrapper {
        border-radius: var(--block-radius);
        color: var(--primary-color);
        background-color: var(--primary-assertive);
        position: relative;

        @media (max-width: $tabletPortrait) {
            overflow: hidden;
        }

        .text {
            margin: var(--margin-text-v) auto;
            text-align: left;
            width: var(--text-width);

            a {
                color: inherit;
            }
        }
    }

    .section-title b,
    .section-title strong,
    .section-subtitle {
        color: var(--primary-brand);
    }

    .section-default__footer {
        @include z-index(2);
        position: relative;
        gap: var(--gap);
    }

    .images {
        .image {
            position: absolute;

            figure, img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }

            &:first-child {
                @include z-index(1);
                height: rem(260);
                width: rem(189);
                left: 3%;
                bottom: 15%;
            }
            
            &:nth-child(2) {
                @include z-index(0);
                height: rem(350);
                width: rem(250);
                left: 10%;
                top: 22%;

                @media (max-width: $tabletPortrait) {
                    left: -10%;
                }
            }
            
            &:nth-child(3) {
                @include z-index(0);
                width: rem(286);
                height: rem(350);
                right: 9%;
                top: 14%;

                @media (max-width: $tabletPortrait) {
                    right: -10%;
                }
            }
            
            &:nth-child(4) {
                @include z-index(1);
                width: rem(165);
                height: rem(246);
                right: 14%;
                bottom: 12%;

                @media (max-width: $tabletPortrait) {
                    right: 6%;
                }
            }
        }
    }
    
    @media (max-width: $smartphone) {
        .images {
            display: none;
        }

        .text {
            margin: var(--padding-xxl) auto;
        }

        .section-default__footer {
            flex-direction: column;
        }
    }
}

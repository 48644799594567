.widget-book-now {
    --text-width: #{rem(440)};
    
    @media (max-width: $smartphone) {
        --text-width: 100%;
    }
}

.widget-book-now {
    background: var(--white);

    .page-booking & {
        min-height: 100vh;
    }
    
    @media (max-width: $smartphone) {
        min-height: 100vh;
        
        .section-title,
        .book-banner-mirai {
            display: none;
        }
    }

    @media (min-width: $smartphone) {
        .book-banner-mirai {
            form {
                background: var(--primary-light);
            }
        }
    }

    [data-mirai-engine="mirai_rs"] {
        @include font-sans();
        
        .mi-rs-rate-night-price {
            color: var(--primary-brand);
        }

       /* button.mi-rs-rate-select-room-btn {
            @include button();
            font-size: var(--font-size-xxxsmall) !important;
        }*/

        /*.mi-rs-rate-qty .mi-rs-rate-select-room-btn .mi-ico-arrow-right-dark {
            margin-top: 0;
            margin-left: var(--padding-xs);
        }*/

        button.mi-rs-add-btn,
        button.mi-rs-cart-btn,
        button.mi-rs-rate-select-room-btn {
            border-radius: .42em;
            background-color: var(--primary-brand);

            &:hover  {
                background-color: var(--primary-assertive);
            }
        }
    }
}